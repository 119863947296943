import React, { useEffect, useState } from "react"
import Select from "react-select"
import { classNames } from "../../utils/classNames"
import CurrencyInput from "react-currency-input-field"
import { AddAmendContactModal } from "../add-amend-contact-modal"
import { ContractDocumentsList } from "../contract-documents-list"

export interface IOrderDetailsFormProps {
  contractId: string
  contactsList: Record<string, any>[]
  orderDetailsFormValues: Record<string, any>
  handleOrderDetailChange: (
    key: string,
    value: string | Record<string, any>
  ) => void
  orderDetailEnquiryTypes: Record<string, any>[]
  automaticDeliveryNotes?: string
  contractDocuments: Record<string, any>[]
  handleRefreshContractDocuments: () => void
  purchaseOrderNoValidation: Record<string, any> | null
  isQuote: boolean
}

export const OrderDetailsForm = ({
  contractId,
  contactsList,
  orderDetailsFormValues,
  handleOrderDetailChange,
  orderDetailEnquiryTypes,
  automaticDeliveryNotes,
  contractDocuments,
  handleRefreshContractDocuments,
  purchaseOrderNoValidation,
  isQuote,
}: IOrderDetailsFormProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [isOpenConfirmContact, setIsOpenConfirmContact] = useState<boolean>(false)
  const [canAmend, setCanAmend] = useState<boolean>(false)
  const [confirmCTA, setConfirmCTA] = useState<Record<string, any>>({
    title: "",
    CTA: "",
  })
  const buttonClassName =
    "normal-case px-5 py-2.5 border text-xs 2xl:text-sm 4xl:text-md font-medium rounded-full hover:bg-filteringPillsHover hover:text-white transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2"

  const initialContactValue = {
    label: "",
    value: null,
  }

  const initialNewContactState: Record<string, any> = {
    contactFirstName: "",
    contactSurname: "",
    contactTelephone: "",
    contactEmail: "",
  }

  const [orderNewContactValues, setNewContactValues] = useState<
    Record<string, any>
  >(initialNewContactState)

  const [previousOrderContact, setPreviousOrderContact] = useState<Record<string, any>>({})
  const [purchaseOrderNoRegExp, setPurchaseOrderNoRegExp] = useState<RegExp>()
  const [purchaseOrderNoValidationResult, setPurchaseOrderNoValidationResult] = useState<string>("")

  function openModal() {
    setIsOpen(true)
  }

  const onCreateContact = () => {
    setNewContactValues(initialNewContactState)
    setCanAmend(false)
    openModal()
  }

  const decideModalLabels = () => {
    if (orderNewContactValues.contactId) {
      setConfirmCTA({ title: "UPDATE EXISTING CONTACT", cta: "UPDATE CONTACT" })
    } else {
      setConfirmCTA({ title: "CREATE NEW CONTACT", cta: "ADD CONTACT" })
    }
  }

  const validateOrderNumber = (value: string) => {
    if (purchaseOrderNoValidation?.purchaseOrderNoMandatory && value.length === 0) {
      setPurchaseOrderNoValidationResult("WARNING: NOT PROVIDED")
      return
    }

    if (purchaseOrderNoRegExp) {
      if (!purchaseOrderNoRegExp.test(value)) {
        setPurchaseOrderNoValidationResult("WARNING: DOES NOT MATCH PATTERN")
        return
      }
    }

    setPurchaseOrderNoValidationResult("")
  }

  useEffect(() => {
    decideModalLabels()
    if (orderDetailsFormValues?.contact?.value != null) {
      setNewContactValues(orderDetailsFormValues.contact.value)
      setCanAmend(true)
    } else {
      setCanAmend(false)
    }
  }, [orderDetailsFormValues?.contact])

  useEffect(() => {
    if (purchaseOrderNoValidation?.purchaseOrderNoRegEx) {
      setPurchaseOrderNoRegExp(new RegExp(purchaseOrderNoValidation.purchaseOrderNoRegEx, "i"))
    }
  }, [purchaseOrderNoValidation])

  useEffect(() => {
    validateOrderNumber(orderDetailsFormValues.orderNumber)
  }, [orderDetailsFormValues.orderNumber])

  return (
    <>
      <form className={"grid grid-cols-3 gap-6"}>
        <section className={"flex flex-col gap-4"}>
          <div className={"flex flex-col gap-2"}>
            <div className={"flex flex-row gap-2"}>
              <p className="text-sm font-bold">Order Contact</p>
              <button
                onClick={e => {
                  e.preventDefault()
                  setPreviousOrderContact(orderDetailsFormValues.contact)
                  handleOrderDetailChange("contact", initialContactValue)
                  onCreateContact()
                }}
                className="pb-1 text-right underline bg-white sm:text-xs"
              >
                New
              </button>
              {JSON.stringify(orderNewContactValues) !==
                JSON.stringify(initialNewContactState) &&
                canAmend && (
                  <button
                    onClick={e => {
                      e.preventDefault()
                      setNewContactValues(
                        orderDetailsFormValues?.contact?.value
                      )
                      decideModalLabels()

                      if (orderDetailsFormValues?.contact?.value?.contactId) {
                        setIsOpenConfirmContact(true)
                      } else {
                        openModal()
                      }
                    }}
                    className="pb-1 text-right underline bg-white sm:text-xs"
                  >
                    Amend
                  </button>
                )}
            </div>
            <Select
              id={"contact"}
              value={orderDetailsFormValues.contact}
              placeholder={"Order Contact"}
              isClearable
              className="w-full border-gray-300 rounded-md shadow-sm sm:text-sm"
              onChange={contact => handleOrderDetailChange("contact", contact)}
              options={contactsList}
            />
          </div>
          <div className={"flex flex-col gap-2"}>
            <p className="text-sm font-bold">Notes</p>
            {automaticDeliveryNotes && (
              <p className="p-2 bg-gray-300">{automaticDeliveryNotes}</p>
            )}
            <textarea
              placeholder={"Notes"}
              value={orderDetailsFormValues.notes}
              className="relative w-full py-2 pl-3 pr-10 text-left bg-white border border-gray-300 rounded-md shadow-sm cursor-default h-52 sm:text-sm"
              onChange={(value: any) =>
                handleOrderDetailChange("notes", value.target.value)
              }
            />
          </div>
        </section>
        <section className={"flex flex-col gap-4"}>
          <div className={"flex flex-col gap-2"}>
            <p className="text-sm font-bold">Order placed via</p>
            <div className={"flex flex-row gap-2"}>
              <button
                onClick={e => {
                  e.preventDefault()
                  handleOrderDetailChange("orderPlacedVia", "phone")
                }}
                className={classNames(
                  "phone" === orderDetailsFormValues.orderPlacedVia
                    ? "bg-filteringPillsHover text-white"
                    : "text-black",
                  buttonClassName
                )}
              >
                {"Phone"}
              </button>
              <button
                onClick={e => {
                  e.preventDefault()
                  handleOrderDetailChange("orderPlacedVia", "email")
                }}
                className={classNames(
                  "email" === orderDetailsFormValues.orderPlacedVia
                    ? "bg-filteringPillsHover text-white"
                    : "text-black",
                  buttonClassName
                )}
              >
                {"Email"}
              </button>
            </div>
          </div>
          <div className={"flex flex-col gap-2"}>
            <div className="flex flex-row gap-2">
              <p className="text-sm font-bold">Order Number</p>
              <button
                onClick={e => {
                  e.preventDefault()
                  handleOrderDetailChange("orderNumber", "Awaiting Customer PO")
                }}
                className="pb-1 text-right underline bg-white sm:text-xs"
              >
                Awaiting PO
              </button>
            </div>
            <input
              type="text"
              placeholder={"Order Number"}
              className="relative w-full py-2 pl-3 pr-10 text-left bg-white border border-gray-300 rounded-md shadow-sm cursor-default resize-none h-9 sm:text-sm"
              value={orderDetailsFormValues.orderNumber}
              onChange={(value: any) => {
                handleOrderDetailChange("orderNumber", value.target.value)
              }}
            />
            {!isQuote && (
              <div className="flex flex-col gap-0">
                {purchaseOrderNoValidation?.purchaseOrderNoMandatory && (
                    <p className="flex flex-row text-sm leading-none mb-1 text-orange-600">
                      Customer has specified that their order number must be provided
                    </p>
                  )
                }
                {purchaseOrderNoValidation?.purchaseOrderNoRegEx && (
                  <p className="flex flex-row text-sm leading-none mb-1 text-orange-600">
                    {`${purchaseOrderNoValidation?.purchaseOrderNoRegExMatchMandatory ? "Must": "Should"} match: ${purchaseOrderNoValidation?.purchaseOrderNoRegExDescription}`}
                  </p>
                )}
                {purchaseOrderNoValidationResult && (
                  <p className="flex flex-row text-sm leading-none mb-1 text-red-600">
                    {purchaseOrderNoValidationResult}
                  </p>
                )}
              </div>  
            )}
          </div>
          <div className={"flex flex-col gap-2"}>
            <p className="text-sm font-bold">Order Value</p>
            <CurrencyInput
              id="orderValue"
              name="orderValue"
              placeholder={"Order Value"}
              value={orderDetailsFormValues.orderValue}
              prefix="£"
              decimalsLimit={2}
              onValueChange={(value = "") => {
                handleOrderDetailChange("orderValue", value)
              }}
              allowNegativeValue={false}
              className="relative w-full py-2 pl-3 pr-2 text-left bg-white border border-gray-300 rounded-md shadow-sm cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>
        </section>
        <section className={"flex flex-col gap-4"}>
          <div className={"flex flex-col gap-2"}>
            <p className="text-sm font-bold">Enquiry Type</p>
            <Select
              id={"enquiryType"}
              value={orderDetailsFormValues["enquiryType"]}
              placeholder={"Enquiry Type"}
              isClearable
              className="w-full border-gray-300 rounded-md shadow-sm sm:text-sm"
              onChange={enquiryType =>
                handleOrderDetailChange("enquiryType", enquiryType)
              }
              options={orderDetailEnquiryTypes}
            />
          </div>
          <div className="flex flex-col gap-2">
            <ContractDocumentsList
              contractId={contractId}
              contractDocuments={contractDocuments}
              handleRefreshContractDocuments={handleRefreshContractDocuments}
              titleClassNames="text-sm font-bold"
              actionLinksClassNames="pb-1 text-right underline bg-white sm:text-xs"
            />
          </div>
        </section>
      </form>

      <AddAmendContactModal
        handleDetailChange={handleOrderDetailChange}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        isOpenConfirm={isOpenConfirmContact}
        setIsOpenConfirm={setIsOpenConfirmContact}
        ctaLabel={confirmCTA}
        setNewContactValues={setNewContactValues}
        newContactValues={orderNewContactValues}
        setCanAmend={setCanAmend}
        contactKeyName={"contact"}
        onCancelled={() => {
          if (previousOrderContact) {
            handleOrderDetailChange("contact", previousOrderContact)
          }
        }}
      />
    </>
  )
}
