import React, { Fragment, useState } from "react"
import { Dialog, Transition } from "@headlessui/react"
import { IBadData } from "../../types/interfaces"
import { useResourceStringsNewContracts } from "../pages/cx-dashboard/customer/customer-detail/new-contracts/use-resource-strings"

export interface IAddAmendContactModalProps {
  handleDetailChange: (key: string, value: string | Record<string, any>) => void
  isOpen: boolean
  setIsOpen: (bool: boolean) => void
  isOpenConfirm?: boolean
  setIsOpenConfirm?: (bool: boolean) => void
  ctaLabel: Record<string, any>
  setNewContactValues: any
  newContactValues: Record<string, any>
  setCanAmend: any
  contactKeyName: string
  onCancelled: () => void
}

export const AddAmendContactModal = ({
  handleDetailChange,
  isOpen,
  setIsOpen,
  isOpenConfirm = false,
  setIsOpenConfirm,
  ctaLabel,
  setNewContactValues,
  newContactValues,
  setCanAmend,
  contactKeyName,
  onCancelled,
}: IAddAmendContactModalProps) => {
  const { amendExistingContactWarning } = useResourceStringsNewContracts()
  const [showCreateContactBadDataMessage, setCreateContactShowBadDataMessage] =
    useState<IBadData>({
      showMessage: false,
      message: "",
    })

  function openModal() {
    setIsOpen(true)
  }

  function closeModal() {
    setIsOpen(false)
  }

  function closeModalConfirm() {
    setIsOpenConfirm && setIsOpenConfirm(false)
  }

  const handleNewContactChange = (
    key: string,
    value: string | Record<string, any>
  ) => {
    setNewContactValues({
      ...newContactValues,
      [key]: value,
    })
  }

  const onConfirm = () => {
    const { contactFirstName, contactSurname, contactEmail, contactTelephone, contactMobile } =
      newContactValues || {}
    const inputsExist =
      contactKeyName === "contact"
        ? contactFirstName && contactSurname && contactEmail
        : contactKeyName === "siteContact"
        ? contactFirstName && contactSurname && (contactTelephone || contactMobile)
        : true
    if (inputsExist) {
      const contact = {
        label: `${contactFirstName} ${contactSurname}${((contactFirstName || contactSurname) && contactEmail) ? " - " : "" }${contactEmail ? contactEmail : ""}`,
        value: { ...newContactValues, update: true },
      }
      handleDetailChange(contactKeyName, contact)
      setCanAmend(true)
      setCreateContactShowBadDataMessage({ showMessage: false, message: "" })
      closeModal()
    } else {
      setCreateContactShowBadDataMessage({
        showMessage: true,
        message: `The required fields have not been completed${contactKeyName === "siteContact" && !(contactTelephone || contactMobile) ? " (including phone and/or mobile)" : " "}`,
      })
    }
  }

  const onCancel = () => {
    onCancelled()
    closeModal()
  }

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10 font-normal"
          onClose={closeModal}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md px-2 py-8 overflow-hidden align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                  <Dialog.Title
                    as="h3"
                    className="text-xl font-bold leading-6 text-gray-900"
                  >
                    {ctaLabel.title}
                  </Dialog.Title>
                  <form>
                    <div className="flex flex-col gap-4 m-4">
                      <div className="flex flex-row gap-2">
                        <div>
                          <p className="text-sm font-bold text-left">
                            First Name *
                          </p>
                          <input
                            type="text"
                            placeholder={"First Name *"}
                            className="relative w-full py-2 pl-3 pr-10 text-left bg-white border border-gray-300 rounded-md shadow-sm cursor-default resize-none h-9 sm:text-sm"
                            value={newContactValues.contactFirstName || ""}
                            onChange={(value: any) =>
                              handleNewContactChange(
                                "contactFirstName",
                                value.target.value
                              )
                            }
                          />
                        </div>
                        <div>
                          <p className="text-sm font-bold text-left">
                            Last Name *
                          </p>
                          <input
                            type="text"
                            placeholder={"Last Name *"}
                            className="relative w-full py-2 pl-3 pr-10 text-left bg-white border border-gray-300 rounded-md shadow-sm cursor-default resize-none h-9 sm:text-sm"
                            value={newContactValues.contactSurname || ""}
                            onChange={(value: any) =>
                              handleNewContactChange(
                                "contactSurname",
                                value.target.value
                              )
                            }
                          />
                        </div>
                      </div>
                      <div>
                        <p className="text-sm font-bold text-left">
                          Email {contactKeyName === "contact" ? "*" : ""}
                        </p>
                        <input
                          type="text"
                          placeholder={`Email ${
                            contactKeyName === "contact" ? "*" : ""
                          }`}
                          className="relative w-full py-2 pl-3 pr-10 text-left bg-white border border-gray-300 rounded-md shadow-sm cursor-default resize-none h-9 sm:text-sm"
                          value={newContactValues.contactEmail || ""}
                          onChange={(value: any) =>
                            handleNewContactChange(
                              "contactEmail",
                              value.target.value
                            )
                          }
                        />
                      </div>
                      <div>
                        <p className="text-sm font-bold text-left">
                          Phone {contactKeyName === "siteContact" ? "*" : ""}
                        </p>
                        <input
                          type="text"
                          placeholder={`Phone ${
                            contactKeyName === "siteContact" ? "*" : ""
                          }`}
                          className="relative w-full py-2 pl-3 pr-10 text-left bg-white border border-gray-300 rounded-md shadow-sm cursor-default resize-none h-9 sm:text-sm"
                          value={newContactValues.contactTelephone || ""}
                          onChange={(value: any) =>
                            handleNewContactChange(
                              "contactTelephone",
                              value.target.value
                            )
                          }
                        />
                      </div>
                      <div>
                        <p className="text-sm font-bold text-left">
                          Mobile {contactKeyName === "siteContact" ? "*" : ""}
                        </p>
                        <input
                          type="text"
                          placeholder={`Mobile ${
                            contactKeyName === "siteContact" ? "*" : ""
                          }`}
                          className="relative w-full py-2 pl-3 pr-10 text-left bg-white border border-gray-300 rounded-md shadow-sm cursor-default resize-none h-9 sm:text-sm"
                          value={newContactValues.contactMobile || ""}
                          onChange={(value: any) =>
                            handleNewContactChange(
                              "contactMobile",
                              value.target.value
                            )
                          }
                        />
                      </div>
                      {showCreateContactBadDataMessage.showMessage && (
                        <p className="text-sm font-bold text-horizonred">
                          {showCreateContactBadDataMessage.message}
                        </p>
                      )}
                    </div>
                    <div className="flex flex-row gap-2 mx-4">
                      <div className="flex flex-1">
                        <button
                          onClick={e => {
                            e.preventDefault()
                            onCancel()
                          }}
                          className="w-full px-4 py-2 bg-white border border-gray-500 rounded-full hover:bg-horizonhover hover:text-white hover:border-transparent sm:text-sm"
                        >
                          CANCEL
                        </button>
                      </div>
                      <div className="flex flex-1">
                        <button
                          onClick={e => {
                            e.preventDefault()
                            onConfirm()
                          }}
                          className="w-full px-4 py-2 text-white rounded-full bg-horizonred focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 hover:bg-horizonhover hover:text-white hover:border-transparent sm:text-sm"
                        >
                          {ctaLabel.cta}
                        </button>
                      </div>
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      <Transition appear show={isOpenConfirm} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10 font-normal"
          onClose={closeModalConfirm}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md px-2 py-8 overflow-hidden align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                  <Dialog.Title
                    as="h3"
                    className="text-xl font-bold leading-6 text-gray-900"
                  >
                    CONFIRM AMENDMENT OF EXISTING CONTACT
                  </Dialog.Title>
                  <form>
                    <div className="flex flex-col gap-4 m-4">
                      <div className="flex flex-row gap-2">
                        <p className="text-sm font-bold">
                          {amendExistingContactWarning}
                        </p>
                      </div>
                      {showCreateContactBadDataMessage.showMessage && (
                        <p className="text-sm font-bold text-horizonred">
                          {showCreateContactBadDataMessage.message}
                        </p>
                      )}
                    </div>
                    <div className="flex flex-row gap-2 mx-4">
                      <div className="flex flex-1">
                        <button
                          onClick={e => {
                            e.preventDefault()
                            closeModalConfirm()
                          }}
                          className="w-full px-4 py-2 bg-white border border-gray-500 rounded-full hover:bg-horizonhover hover:text-white hover:border-transparent sm:text-sm"
                        >
                          CANCEL
                        </button>
                      </div>
                      <div className="flex flex-1">
                        <button
                          onClick={e => {
                            e.preventDefault()
                            closeModalConfirm()
                            setTimeout(() => {
                              openModal()
                            }, 300)
                          }}
                          className="w-full px-4 py-2 text-white rounded-full bg-horizonred focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 hover:bg-horizonhover hover:text-white hover:border-transparent sm:text-sm"
                        >
                          CONFIRM
                        </button>
                      </div>
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}
