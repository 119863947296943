import React, { useEffect, useState } from "react"
import Moment from "react-moment"
import { useResourceStringsOutOnHire } from "./use-resource-strings"
import { getCustomerWithAllContractsByCustomerId } from "../../../../../../../middleware/middleware-layer"
import { useMsal } from "@azure/msal-react"

import { CustomerContracts } from "./customer-contract-search-filtering-menu"
import { useCustomerContext } from "../../../store"
import { Link } from "gatsby"
import moment from "moment"
import { NewContractButton } from "../../../../../../new-contract-button"

export default function AllContracts(props: any) {
  const { customer } = useCustomerContext()
  const {
    contractNo,
    status,
    endDate,
    startDate,
    productOrderNo,
    site,
    contact,
    staffEntered,
  } = useResourceStringsOutOnHire()
  const { instance, accounts } = useMsal()
  const [customerContracts, setCustomerContracts] = useState([])
  const [loading, setLoading] = useState(true)
  const [noResponse, setNoResponse] = useState(false)

  useEffect(() => {
    // contracts data for the specific customer
    getCustomerWithAllContractsByCustomerId(
      accounts,
      instance,
      props.customerId
    )
      .then((results: any) => {
        const parseContractData =
          results?.data?.internalGetAllContractsByCustomerId?.contracts.map(
            (contract: any) => {

              let canEditContract = false

              const filterButtonValue = (statusDescription: string) => {
                switch(statusDescription) {
                  case "Firm Booking" : {
                    canEditContract = true
                    return contract.statusDescription
                  }
                  case "Quote Ready":
                  case "Quote Received": {
                    canEditContract = true
                    return "Quotes"
                  }
                  case "Quote Raised": {
                    canEditContract = true
                    return "Unconfirmed"
                  }
                  default:
                    return contract.statusDescription
                }
              }

              return {
                contractNo: contract.contractNo,
                contractId: contract.contractId,
                statusDescription:
                  contract.statusDescription === "Quote Raised"
                    ? "Unconfirmed"
                    : contract.statusDescription,
                hireStartDate: contract.hireStartDate,
                plannedEndDate: contract.plannedEndDate,
                orderNo: contract.orderNo,
                siteName: contract.siteName,
                contact: contract.contact,
                staffEntered: contract.staffEntered,
                filterButtonValue: filterButtonValue(contract.statusDescription),
                canEditContract,
              }
            }
          )

        setCustomerContracts(parseContractData)
        setLoading(false)
      })
      .catch(() => setNoResponse(true))
  }, [])

  
  const columns = [
    {
      id: "contractNo",
      name: contractNo,
      valueKeys: ["contractNo", "contractId", "canEditContract"],
      className: "",
      renderCell: (values?: Record<string, any | string>) => {
        const contractNo = values?.[0] || ""
        const contractId = values?.[1] || ""
        const canEditContract = values?.[2] || false

        return (
          <Link
            to={`/cx-dashboard/customer/${props.customerId}/contracts/${canEditContract ? "saved/" : ""}${contractId}`}
          >
            {contractNo}
          </Link>
        )
      },
    },
    {
      id: "statusDescription",
      name: status,
      valueKeys: ["statusDescription"],
      className: "",
    },
    {
      id: "hireStartDate",
      name: startDate,
      valueKeys: ["hireStartDate"],
      className: "",
      renderCell: (values?: Record<string, any | string>) => {
        const valueString = (values && values.join(", ")) || ""
        return <Moment format="DD-MM-YYYY">{valueString}</Moment>
      },
    },
    {
      id: "plannedEndDate",
      name: endDate,
      valueKeys: ["plannedEndDate"],
      className: "",
      renderCell: (values?: Record<string, any | string>) => {
        const valueStringMoment = moment((values && values.join(", ")) || "")
        return valueStringMoment.isValid() ? valueStringMoment.format("DD-MM-YYYY") : ""
      },
    },
    {
      id: "orderNo",
      name: productOrderNo,
      valueKeys: ["orderNo"],
      className: "",
    },
    {
      id: "siteName",
      name: site,
      valueKeys: ["siteName"],
      className: "",
    },
    {
      id: "contact",
      name: contact,
      valueKeys: ["contact"],
      className: "",
    },
    {
      id: "staffEntered",
      name: staffEntered,
      valueKeys: ["staffEntered"],
      className: "",
    },
  ]

  return (
    <div>
      <div className="flex flex-row justify-end">
        <h2 className="flex-1 inline-flex mt-8 mb-6 mr-6 text-3xl font-bold uppercase">
          {customer?.customerName && customer?.customerName?.toUpperCase()}
        </h2>
        <NewContractButton 
          accounts={accounts}
          className="mt-8"
          customer={customer}
          instance={instance}
        />
      </div>
      {!noResponse ? (
        customer?.customerName && (
          <>
            <CustomerContracts
              customerContracts={customerContracts}
              loading={loading}
              columns={columns}
            />
          </>
        )
      ) : (
        <div>
          <h2 className="w-full py-16 text-2xl font-bold uppercase">
            Issue fetching data
          </h2>
        </div>
      )}
    </div>
  )
}
