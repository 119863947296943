import React, { useState } from "react"
import { useResourceStrings } from "../pages/cx-dashboard/use-resource-strings"
import DatePicker from "react-datepicker"
import { useCustomerContext } from "../pages/cx-dashboard/customer/store"
import { useMsal } from "@azure/msal-react"
import { cancelOffhire, updateContactEmail } from "../../middleware/middleware-layer"
import { IContractDetailItem } from "../../types/interfaces/IContractDetail"
import { classNames } from "../../utils/classNames"
import { Link, navigate } from "gatsby"
import { Switch } from "@headlessui/react"
import Select from "react-select"
import { AddContactEmailModal } from "../add-contact-email-modal"
import AlertMessage from "../alert-message"

export interface IArrangeCollectionFormProps {
  customerId: string
  contractId: string
  contactsList: any[]
}

export const CancelOffHireForm = (props: IArrangeCollectionFormProps) => {
  const {
    canceloffHireNewDatePlaceholder,
    canceloffHireCollectionNotesPlaceholder,
    arrangeCollectionSubmit,
    canceloffHireSubTitle,
    canceloffHireFormIntro,
    canceloffHireCancellationNotesTitle,
    canceloffHireRequesterTitle,
    canceloffHireNewOffhireDateTitle,
    canceloffHireAutoOffhireTitle,
    canceloffHireAutoOffhireErrorMessage,
  } = useResourceStrings()

  const { cancelOffhireItems, availableForCancellation } =
    useCustomerContext() || {}

  const { accounts, instance } = useMsal()

  const selectedOffHireReferences = [
    ...new Set(
      cancelOffhireItems.map(
        (item: Record<string, any>) => item.itemOffHireReference
      )
    ),
  ]

  const filterBySequenceNumber = (arr1: [], arr2: []) => {
    let res = []
    res = arr1.filter((el: Record<string, any>) => {
      return !arr2.find((element: Record<string, any>) => {
        return element?.sequenceNo === el?.sequenceNo
      })
    })
    return res
  }

  const nonSelectedCancelItems = filterBySequenceNumber(
    availableForCancellation.filter((item: any) => {
      return selectedOffHireReferences.find((string: any) => {
        return string === item.itemOffHireReference
      })
    }),
    cancelOffhireItems
  )

  const initialFormState: Record<string, any> = {
    contact: { label: "", value: null },
    newOffHireDate: null,
    isAutoOffhire: false,
    notes: "",
  }
  const [formValues, setFormValues] =
    useState<Record<string, any>>(initialFormState)
  const [badSubmit, setBadSubmit] = useState<boolean>(false)
  const [pickerCollectionDate, setPickerCollectionDate] = useState<Date>()
  const [displayAutoOffHireError, setDisplayAutoOffHireError] =
    useState<boolean>(false)
  const [submitting, setSubmitting] = useState(false)
  const [isOpenContactEmailModal, setIsOpenContactEmailModal] = useState<boolean>(false)
  const [updateContactEmailFailed, setUpdateContactEmailFailed ] = useState(false)

  const submitDisabled =
    !cancelOffhireItems?.length ||
    !formValues?.contact?.value ||
    cancelOffhireItems?.length === 0 ||
    !formValues?.notes ||
    formValues?.arrangeCollectionOffhireDate === null ||
    submitting

  const toggleAutoOffhire = () => {
    if (formValues.newOffHireDate === null) {
      setFormValues({
        ...formValues,
        isAutoOffhire: false,
      })
      setDisplayAutoOffHireError(true)
      return
    }
    if (displayAutoOffHireError === true) {
      setDisplayAutoOffHireError(false)
    }
    handleOnChange("isAutoOffhire", !formValues?.isAutoOffhire)
  }

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault()

    // If the contact doesn't have an email address we will never send the off hire confirmation email, so
    // check and ask for it if necessary.
    if (!formValues?.contact?.value?.contactEmail) {
      setIsOpenContactEmailModal(true)
      return
    }

    submitForm()
  }

  const submitForm = async () => {  
    setBadSubmit(false)
    setUpdateContactEmailFailed(false)
    setSubmitting(true)
    try {
      const preparedOffhireItems = nonSelectedCancelItems.map(
        (item: IContractDetailItem) => ({
          stockNumber: item.stockNumber,
          sequenceNo: item.sequenceNo,
        })
      )

      const preparedCancelOffhireItems = cancelOffhireItems.map(
        (item: IContractDetailItem) => ({
          stockNumber: item.stockNumber,
          sequenceNo: item.sequenceNo,
        })
      )

      var newOffhireDate
      if(formValues?.newOffHireDate !== null) {
        newOffhireDate = new Date(formValues?.newOffHireDate)
        newOffhireDate.setHours(newOffhireDate.getHours() + 1)
        newOffhireDate = newOffhireDate.toISOString().substring(0,11) + "00:00:00.000Z"
      } else {
        newOffhireDate = ""
      }
 
      const result = await cancelOffhire(
        accounts,
        instance,
        +props.customerId,
        +props.contractId,
        preparedOffhireItems,
        preparedCancelOffhireItems,
        formValues.contact.value,
        newOffhireDate,
        formValues.isAutoOffhire,
        formValues?.notes
      )
      
      if (result.errors?.length > 0) {
        console.log(JSON.stringify(result.errors))
        setSubmitting(false)
        setBadSubmit(true)
      } else {
        navigate(
          `/cx-dashboard/customer/${props.customerId}/contracts/${props.contractId}`
        )
      }
    } catch (error) {
      setSubmitting(false)
      setBadSubmit(true)
    }
  }

  const handleOnChange = (key: string, value: any) => {
    setFormValues({
      ...formValues,
      [key]: value,
    })
  }

  const handleEmailAddressEntered = async (contactEmail: string) => {
    // Update the contact's email address, and if successful set the form value to suit and then re-submit the off hire form.
    setIsOpenContactEmailModal(false)

    const result = await updateContactEmail(
      accounts, 
      instance, 
      formValues.contact.value.contactId,
      contactEmail
    )

    if (result.data.internalUpdateContact.contactId === formValues.contact.value.contactId) {
      formValues.contact.value.contactEmail = contactEmail
      submitForm()
    } else {
      setUpdateContactEmailFailed(true)
    }
  }

  return (
    <>
      <form className="ml-5" onSubmit={handleSubmit}>
        <div>
          <h2 className="text-2xl font-bold uppercase">
            {canceloffHireSubTitle}
          </h2>
          <p className="text-lg">{canceloffHireFormIntro}</p>
          <p className="mt-5 text-lg">An '*' indicates a required field.</p>
        </div>

        <div className="">
          <h3 className="my-5 text-lg font-bold">
            {canceloffHireRequesterTitle}
          </h3>
          <Select
            id={"contact"}
            value={formValues["contact"]}
            placeholder={"Select Requester"}
            isClearable
            className="w-full border-gray-300 rounded-md shadow-sm sm:text-sm"
            onChange={contactName => handleOnChange("contact", contactName)}
            options={props.contactsList}
          />

          <h3 className="my-5 text-lg font-bold">
            {canceloffHireCancellationNotesTitle}
          </h3>
          <textarea
            required
            placeholder={canceloffHireCollectionNotesPlaceholder}
            className="w-full py-2 pl-3 pr-10 text-left bg-white border border-gray-300 rounded-md shadow-sm cursor-default h-52 sm:text-sm"
            onChange={(value: any) => handleOnChange("notes", value.target.value)}
          />

          <div>
            <h3 className="my-5 text-lg font-bold">
              {canceloffHireNewOffhireDateTitle}
            </h3>
            <div className="relative">
              <DatePicker
                selected={pickerCollectionDate ? pickerCollectionDate : null}
                onChange={(date: any) => {
                  handleOnChange("newOffHireDate", date)
                  setPickerCollectionDate(date)
                }}
                dateFormat="dd-MM-yyyy"
                showDisabledMonthNavigation
                disabled={!cancelOffhireItems || cancelOffhireItems.length === 0}
                placeholderText={canceloffHireNewDatePlaceholder}
                className="relative w-full py-2 pl-3 pr-10 text-left bg-white border border-gray-300 rounded-md shadow-sm cursor-default sm:text-sm"
              />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={22.065}
                height={24.406}
                className="absolute top-[7px] right-[15px]"
              >
                <defs>
                  <style>
                    {
                      ".a{fill:none;stroke:#4c4a4a;stroke-linecap:round;stroke-linejoin:round}"
                    }
                  </style>
                </defs>
                <path
                  className="a"
                  d="M2.841 2.953h16.383a2.334 2.334 0 0 1 2.341 2.328v16.3a2.334 2.334 0 0 1-2.341 2.328H2.841A2.334 2.334 0 0 1 .5 21.578V5.281a2.334 2.334 0 0 1 2.341-2.328ZM15.714.5v4.906M6.351.5v4.906M.5 9.862h21.065"
                />
              </svg>
            </div>
          </div>

          <div className="flex flex-col w-full">
            <div className="flex items-center w-full mt-5">
              <Switch
                checked={formValues.isAutoOffhire}
                onChange={toggleAutoOffhire}
                className={`${
                  formValues.isAutoOffhire ? "bg-blue-600" : "bg-gray-200"
                } relative inline-flex h-6 w-11 items-center rounded-full`}
              >
                <span className="sr-only">Enable notifications</span>
                <span
                  className={`${
                    formValues.isAutoOffhire ? "translate-x-6" : "translate-x-1"
                  } inline-block h-4 w-4 transform rounded-full bg-white transition`}
                />
              </Switch>
              <p className="ml-3 text-lg font-bold">
                {canceloffHireAutoOffhireTitle}
              </p>
            </div>
            <small
              className={`${
                displayAutoOffHireError ? `d-block` : `hidden`
              } text-horizonred mt-3`}
            >
              {canceloffHireAutoOffhireErrorMessage}
            </small>
          </div>

          <AlertMessage
            mode="warning"
            messages={[
              "Submitting..."
            ]}
            animate
            show={submitting}
          />

          <AlertMessage
            show={badSubmit}
            mode="danger"
            messages={[
              "There was a problem when submitting the cancellation.",
              "The off-hire may not have been cancelled.",
              "The off-hire cancellation email may not have been sent.",
              "Please notify the relevant supplier if necessary."
            ]}
          />

          <AlertMessage
            show={updateContactEmailFailed}
            mode="danger"
            messages={[
              "There was a problem adding the contact email."
            ]}
          />
          
          <div className="inline-flex justify-between w-full mt-4 mb-8 sm:border-l sm:border-transparent">
            <div className="flex flex-col justify-center">
              <Link
                to={`/cx-dashboard/customer/${props.customerId}/contracts/${props.contractId}`}
                className="text-xs text-right underline"
              >
                Return to order details
              </Link>
            </div>
            <div className="flex flex-col justify-center">
              <button
                type="submit"
                className={classNames(
                  submitDisabled
                    ? "cursor-not-allowed"
                    : " hover:bg-horizonhover",
                  "px-6 py-2 text-sm text-white uppercase border-transparent rounded-full bg-horizonred hover:text-grey-300"
                )}
                disabled={submitDisabled}
              >
                {arrangeCollectionSubmit}
              </button>
            </div>
          </div>
        </div>
      </form>

      <AddContactEmailModal
        isOpen={isOpenContactEmailModal}
        setIsOpen={setIsOpenContactEmailModal}
        handleEmailAddressEntered={handleEmailAddressEntered}
      />
    </>
  )
}
