import React, { useEffect, useState } from "react"
import { getKPIByCustomer } from "../../../../../../middleware/middleware-layer";
import { useMsal } from "@azure/msal-react";
import { HireStatisticsCharts } from "../../../../../hire-statistics";
import { NewContractButton } from "../../../../../new-contract-button";
import { useCustomerContext } from "../../store";
import moment from "moment";
import Select from "react-select"

export default function HireStatistics(props: any) {
    const { customer } = useCustomerContext()
    const { instance, accounts } = useMsal()
    const [isLoading, setIsLoading] = useState(true)
    const [statisticsUnavailable, setStatisticsUnavailable] = useState(false)
    const [chartData, setChartData] = useState([])
    const [timePeriods, setTimePeriods] = useState<Record<string, any>[]>([])
    const [selectedTimePeriod, setSelectedTimePeriod] = useState<Record<string, any>>()

    const handleTimePeriodChange = (item: Record<string, any>) => {
        setSelectedTimePeriod({
            label: item.label,
            value: item,
        })
        const { startDate, endDate } = item.boundary;
        getCharts(startDate, endDate)
    }

    const populateTimePeriods = () => {
        let periods = []
        const today = new Date()
        const endDate = new Date(today.getFullYear(), today.getMonth() + 1, 0)
        endDate.setHours(23, 59, 59, 0)
        const startDate = new Date(today.getFullYear(), today.getMonth(), 1)
        startDate.setMonth(today.getMonth() - 11)
        startDate.setHours(0, 0, 0, 0)
        
        for (let i = 0; i < 12; i++) {
          const periodEndDate = new Date(today.getFullYear(), (today.getMonth() + 1) - i, 0)
          periodEndDate.setHours(23, 59, 59, 0)
          const periodStartDate = new Date(periodEndDate.getFullYear(), periodEndDate.getMonth() - 11, 1)
          periodStartDate.setHours(0, 0, 0, 0)
          periods.push({
            boundary: {
              startDate: getDateTimezoneOffset(periodStartDate),
              endDate: getDateTimezoneOffset(periodEndDate),
            },
            label: moment(periodEndDate).format("MMMM YYYY"),
          })
        }

        setSelectedTimePeriod({
            label: periods[0].label,
            value: periods[0],
        })

        setTimePeriods(periods)
        return periods
    };

    const getDateTimezoneOffset = (date: Date) => {
        let offset = date.getTimezoneOffset()
        if (offset !== 0) {
            offset = offset * -1
        }
        return moment(new Date(date.getTime() + (offset * 60000))).format("YYYY-MM-DDTHH:mm:ss.0000Z")
    }

    const getCharts = async (startDate: string, endDate: string) => {
        setIsLoading(true)
  
        getKPIByCustomer(
            accounts,
            instance,
            props.customerId,
            startDate, 
            endDate,
        ).then((results) => {
            if (results.data.internalGetKPIByCustomer) {
                setChartData(results.data.internalGetKPIByCustomer.charts)
              } else {
                setStatisticsUnavailable(true)
              }
        }).catch(() => {
            setStatisticsUnavailable(true)
        }).finally(() => {
            setIsLoading(false)
        })        
    }

    useEffect(() => {
        const timePeriods = populateTimePeriods()
        const { startDate, endDate } = timePeriods[0].boundary
        getCharts(startDate, endDate)
    }, [props.customerId])

    return props.customerId ? (
        <>
        <div className="flex flex-row justify-end mb-6">
            <h2 className="flex-1 inline-flex mt-8 mb-6 mr-6 text-3xl font-bold uppercase">
                {customer?.customerName && customer?.customerName?.toUpperCase()}
            </h2>
            <NewContractButton 
                accounts={accounts}
                className="mt-8"
                customer={customer}
                instance={instance}
            />
            </div>
            <div className="flex flex-row w-full items-center mb-10">
                <div className="flex flex-col mr-4">
                <label>Showing 12 month period ending</label>
                </div>
                <div className="flex flex-col w-[200px]">
                    <div>
                        <Select
                            isSearchable={false}
                            name="SelectedTimePeriod"
                            onChange={option => {
                                handleTimePeriodChange(option?.value as Record<string, any>)
                            }}
                            options={
                                timePeriods?.map((item: Record<string, any>) => {
                                    return {
                                        label: item.label,
                                        value: item
                                    }
                                })
                            }
                            value={selectedTimePeriod}
                        />
                    </div>
                </div>
            </div>
            <HireStatisticsCharts
                charts={chartData}
                isLoading={isLoading}
                statisticsUnavailable={statisticsUnavailable}
            />
        </>
    ) : null
}