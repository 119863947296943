import React, { useEffect, useState } from "react"
import { useMsal } from "@azure/msal-react"
import GridSystem from "../../../../../../../tailwind-grid/grid"
import ColSpanSix from "../../../../../../../tailwind-grid/col-span-six"
import { useResourceStrings } from "../../../../../use-resource-strings"
import { DisplayProductCard } from "../../../../../../../display-product-card"
import { IBadData } from "../../../../../../../../types/interfaces/IBadData"
import {
  getAvailableOffhireItemsByContractId,
  getContactsForSiteBySiteId,
} from "../../../../../../../../middleware/middleware-layer"
import { useProductData } from "../../../../../../../../graphql-static/use-commercetools-products"
import { useCustomerContext } from "../../../../store"
import { IContractDetailItem } from "../../../../../../../../types/interfaces/IContractDetail"
import { CancelOffHireForm } from "../../../../../../../cancel-offhire-form"
import { transformContactObjToOption } from "../../../../../../../../utils/transformContactObjToOption"

const CancelOffhire = (props: any) => {
  const { customer, cancelOffhireItems, setCanceloffHireItems, selectedContract } = useCustomerContext()
  const { canceloffHireTitle } = useResourceStrings()
  const [showBadDataMessage, setShowBadDataMessage] = useState<IBadData>({
    showMessage: false,
    message: "",
  })
  const [contractSiteId, setContractSiteId] = useState<
    null | number | undefined
  >(null)
  const [contactsForSiteBySiteId, setContactsForSiteBySiteId] = useState([])
  const { accounts, instance } = useMsal()
  const productData = useProductData()

  useEffect(() => {
    if (accounts.length === 0) {
      setShowBadDataMessage({
        showMessage: true,
        message: "Could not retrieve your account information",
      })
      return
    }
    if (!cancelOffhireItems.length) {
      getAvailableOffhireItemsByContractId(
        accounts,
        instance,
        Number(props.contractId)
      ).then(result => {
        if (result.contractId === "") {
          setShowBadDataMessage({
            showMessage: true,
            message: `We could not find any contract data with ID ${props.contractId}`,
          })
        } else {
          setContractSiteId(result?.siteId)
          setCanceloffHireItems(result.availableForOffhire)
          setShowBadDataMessage({ showMessage: false, message: "" })
        }
      })
    } else {
      setContractSiteId(selectedContract?.siteId)
    }
  }, [])
  useEffect(() => {
    if (props.customerId && contractSiteId) {
      getContactsForSiteBySiteId(
        accounts,
        instance,
        +props.customerId,
        +contractSiteId
      ).then(result => {
        if (
          result?.data?.internalGetContactsForSiteBySiteId?.contacts?.length
        ) {
          setContactsForSiteBySiteId(
            result.data.internalGetContactsForSiteBySiteId.contacts.map(
              transformContactObjToOption
            )
          )
          setShowBadDataMessage({ showMessage: false, message: "" })
        } else {
          setShowBadDataMessage({
            showMessage: true,
            message: `We could not find any contacts with Customer ID ${props.customerId} and Site ID ${contractSiteId}`,
          })
        }
      })
    }
  }, [customer, contractSiteId])
  return (
    <>
      <h1 className="mb-8 text-3xl font-bold uppercase">
        {canceloffHireTitle}
      </h1>
      <GridSystem>
        <ColSpanSix>
          {cancelOffhireItems.map((item: IContractDetailItem) => {
            return (
              <DisplayProductCard
                key={item.sequenceNo}
                productName={item.equipmentDesc}
                productCode={item.sku}
                productData={productData}
                sequenceNo={item.sequenceNo}
                offhireReference={item.itemOffHireReference}
                pathway="cancel"
              />
            )
          })}

          {showBadDataMessage.showMessage && (
            <p className="w-full py-16 text-2xl font-bold uppercase">
              {showBadDataMessage.message}
            </p>
          )}
        </ColSpanSix>
        <ColSpanSix>
          <CancelOffHireForm
            customerId={props.customerId}
            contractId={props.contractId}
            contactsList={contactsForSiteBySiteId}
          />
        </ColSpanSix>
      </GridSystem>
    </>
  )
}

export default CancelOffhire
