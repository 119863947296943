import React from "react"

export const FilterIcon = () => (
  <svg
    className="w-auto h-5 mr-2"
    xmlns="http://www.w3.org/2000/svg"
    width="24.003"
    height="21.803"
    viewBox="0 0 24.003 21.803"
  >
    <path
      id="Icon_feather-filter"
      data-name="Icon feather-filter"
      d="M25,4.5H3l8.8,10.407V22.1l4.4,2.2v-9.4Z"
      transform="translate(-2 -3.5)"
      fill="none"
      stroke="#3a3a3a"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
  </svg>
)
