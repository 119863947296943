import React, { useEffect, useState } from "react"
import { useMsal } from "@azure/msal-react"
import GridSystem from "../../../../../../../tailwind-grid/grid"
import ColSpanSix from "../../../../../../../tailwind-grid/col-span-six"
import { useResourceStrings } from "../../../../../use-resource-strings"
import { DisplayProductCard } from "../../../../../../../display-product-card"
import { OffHireForm } from "../../../../../../../off-hire-form"
import { IBadData } from "../../../../../../../../types/interfaces"
import {
  getAvailableOffhireItemsByContractId,
  getContactsForSiteBySiteId,
  getContractById,
  internalGetDefaultAndCustomerAdditionalTransportCharges,
} from "../../../../../../../../middleware/middleware-layer"
import { useProductData } from "../../../../../../../../graphql-static/use-commercetools-products"
import { useCustomerContext } from "../../../../store"
import { IContractDetailItem } from "../../../../../../../../types/interfaces/IContractDetail"
import { transformContactObjToOption } from "../../../../../../../../utils/transformContactObjToOption"
import { navigate } from "gatsby"
import { OffhireCollectionChargeModal } from "../../../../../../../offhire-collection-charge-modal"

const ArrangeCollection = (props: any) => {
  const { customer, selectedContract, setSelectedContract, offHireItems, setOffHireItems } =
    useCustomerContext()
  const { arrangeCollectionTitle } = useResourceStrings()
  const [loadingItems, setLoadingItems] = useState(false)
  const [showBadDataMessage, setShowBadDataMessage] = useState<IBadData>({
    showMessage: false,
    message: "",
  })
  const [contractSiteId, setContractSiteId] = useState<
    null | number | undefined
  >(null)
  const [contactsForSiteBySiteId, setContactsForSiteBySiteId] = useState([])
  const { accounts, instance } = useMsal()
  const productData = useProductData()

  const [siteAddress, setSiteAddress] = useState({})

  const [collectionChargeDetails, setCollectionChargeDetails] = useState<Record<string, any>>({
    isPartial: false,
    collectionCharge: 0,
    suggestedCollectionChargeAmount: 0,
    modalIsOpen: false,
  })

  const [isCollectionChargeModalOpen, setIsCollectionChargeModalOpen] = useState<boolean>(false)

  // This gets the applicable additional transport charge tiers and charges for the items being offhired.
  const getAdditionalTransportCharges = (offhireItems: any, customerAdditionalTransportCharges: any) => {
    let hasStandardProduct = false

    const additionalTransportCharges = offhireItems.reduce((acc: any, curr: Record<string, any>) => {
      if (curr.additionalTransportChargeTier) {
        let matchingTier = acc.find((t: any) => t.key === curr.additionalTransportChargeTier)
        if (!matchingTier) {
          acc.push({
            key: curr.additionalTransportChargeTier,
            charge: customerAdditionalTransportCharges[curr.additionalTransportChargeTier],
            products: [curr.stockNumber]
          })
        } else {
          matchingTier.products.push(curr.stockNumber)
        }
      } else {
        hasStandardProduct = true
      }

      return acc
    }, [])

    return {
      additionalTransportCharges,
      hasStandardProduct
    }
  }

  // When the offhire is complete, check if we might have a partial or final collection charge that 
  // includes additional transport charges, so the user can decide if it is correct.
  const handleOffHireSuccess = async (confirmationNumber: string) => {
    let showCollectionChargeModal = false

    // Check if any items on the contract have an additional transport charge tier. If no, there's no
    // need to check anything, just go to the contract page.
    const itemsWithAdditionalTransportCharge = selectedContract.items.reduce((acc: any, curr: any) => {
      if (curr.additionalTransportChargeTier) {
        acc.push(curr)
      }
      return acc
    }, [])
  
    if (itemsWithAdditionalTransportCharge.length > 0) {
      // Check if this was a partial offhire.
      let isPartial = false
      let collectionCharge

      if (offHireItems.length !== selectedContract.availableForOffhire.length) {
        // It is partial, so get the partial collection charge from the offhire.
        // This means we need to get the contract detail again with the (now) added partial collection charge.
        isPartial = true

        setLoadingItems(true)
        await getContractById(accounts, instance, +props.contractId)
          .then((result) => {
            setLoadingItems(false)
            if (result.contractId !== "") {
              collectionCharge = result.additionalItems.find(({ equipmentCategory, stockNumber, equipmentDesc }: Record<string, any>) =>
                equipmentCategory === "_TRSP" && stockNumber === "_COLLECTION" && equipmentDesc === `Partial Collection ${confirmationNumber}`
              )
            }
          })
      } else {
        // Not partial, so get the final collection charge from the contract.
        collectionCharge = selectedContract.additionalItems.find(({ equipmentCategory, stockNumber }: Record<string, any>) =>
          equipmentCategory === "_TRSP" && stockNumber === "_COLLECTION"
        )
      }

      if (collectionCharge && collectionCharge.chargeAmount) {
        // Get the additional transport charges for the customer.
        let customerAdditionalTransportCharges: any
        await internalGetDefaultAndCustomerAdditionalTransportCharges(
          accounts,
          instance,
          +customer?.customerId,
        )
        .then((result: Record<string, any>) => {
            const charges = result?.data?.internalGetDefaultAndCustomerAdditionalTransportCharges
    
            if (charges) {
              // Merge the customer and default charges
              customerAdditionalTransportCharges = {}
              Object.keys(charges.default).map((key) => {
                customerAdditionalTransportCharges[key] = charges.customer[key] || charges.default[key]
              })
            }
        })
        .catch(err => {
            console.log(err)
        })
        
        if (customerAdditionalTransportCharges) {
          // Get the transport charge for each of the offhire items categories (e.g. standard, tier1, tier2 etc.).
          const { 
            additionalTransportCharges, 
            hasStandardProduct 
          } = getAdditionalTransportCharges(offHireItems, customerAdditionalTransportCharges)

          const standardTransportChargeAmount = hasStandardProduct ? parseFloat(customer.transportRate) : 0

          const additionalTransportChargeAmount = additionalTransportCharges.reduce((acc: number, curr: any) => {
            return acc + curr.charge
          }, 0.00)

          const suggestedCollectionChargeAmount = standardTransportChargeAmount + additionalTransportChargeAmount

          // Compare the calculated charge and the actual partial or final collection charge. If there is a difference 
          // then ask the user if they want to update it.
          if (parseFloat(suggestedCollectionChargeAmount) !== parseFloat(collectionCharge.chargeAmount)) {
            showCollectionChargeModal = true
            setCollectionChargeDetails({
              isPartial,
              collectionCharge,
              suggestedCollectionChargeAmount,
            })
            setIsCollectionChargeModalOpen(showCollectionChargeModal)
          }
        }       
      }
    }

    // If we got here we either had only standard items being offhired, or something didn't work out when 
    // getting the various charges.
    if (!showCollectionChargeModal) {
      navigateToContractPage()
    }
  }

  const navigateToContractPage = () => {
    navigate(
      `/cx-dashboard/customer/${props.customerId}/contracts/${props.contractId}`
    )
  }

  useEffect(() => {
    if (accounts.length === 0) {
      setShowBadDataMessage({
        showMessage: true,
        message: "Could not retrieve your account information",
      })
      return
    }
    
    // Required to get the site address details for the off hire form mapping
    setLoadingItems(true)
    getContractById(accounts, instance, Number(props.contractId))
      .then(async (result) => {
        if (result.contractId === "") {
          setShowBadDataMessage({
            showMessage: true,
            message: `We could not find any contract data with ID ${props.contractId}`,
          })
        } else {
          setSelectedContract && setSelectedContract(result)
          setSiteAddress({
            addressLine1: result.siteAddLine1 || "",
            addressLine2: result.siteAddLine2 || "",
            addressTown: result.siteTown || "",
            addressCounty: result.siteCounty || "",
            addressPostcode: result.sitePostCode || "",
            addressLat: "",
            addressLong: "",
            addressWhat3Words: "",
          })
          setContractSiteId(result.siteId)
          setShowBadDataMessage({ showMessage: false, message: "" })
        }
        setLoadingItems(false)
      })
    
    if (!offHireItems.length) {
      setLoadingItems(true)
      getAvailableOffhireItemsByContractId(
        accounts,
        instance,
        Number(props.contractId)
      )
        .then(result => {
          setLoadingItems(false)
          if (result.contractId === "") {
            setShowBadDataMessage({
              showMessage: true,
              message: `We could not find any contract data with ID ${props.contractId}`,
            })
          } else {
            setOffHireItems(result.availableForOffhire)
            setShowBadDataMessage({ showMessage: false, message: "" })
          }
        })
        .catch(err => {
          console.log(err)
          setLoadingItems(false)
          setShowBadDataMessage({
            showMessage: true,
            message: `We could not fetch contract data with ID ${props.contractId}`,
          })
        })
    }
  }, [])

  useEffect(() => {
    if (props.customerId && contractSiteId && customer) {
      getContactsForSiteBySiteId(
        accounts,
        instance,
        +props.customerId,
        +contractSiteId
      ).then(result => {
        if (
          result?.data?.internalGetContactsForSiteBySiteId?.contacts?.length
        ) {
          setContactsForSiteBySiteId(
            result.data.internalGetContactsForSiteBySiteId.contacts.map(
              transformContactObjToOption
            )
          )
          setShowBadDataMessage({ showMessage: false, message: "" })
        } else {
          setShowBadDataMessage({
            showMessage: true,
            message: `We could not find any contacts with Customer ID ${props.customerId} and Site ID ${customer.mainAddressId}`,
          })
        }
      })
    }
  }, [props.customerId, contractSiteId])

  const getWarningMessage = (item: any) => {
    if (item.supplierName && !item.supplierContactEmail) {
      return `${item.supplierContactName ? item.supplierContactName : "The contact"} at ${item.supplierName} does not have an email address - no off-hire confirmation email will be sent to ${item.supplierName} for this item.`
    }
  }

  return (
    <>
      <h1 className="mb-8 text-3xl font-bold uppercase">
        {arrangeCollectionTitle.toUpperCase()}
      </h1>
      <GridSystem>
        <ColSpanSix>
          {loadingItems && <p className="p-5 text-lg">Loading ...</p>}
          {offHireItems.map((item: IContractDetailItem) => {
            return (
              <DisplayProductCard
                key={item.sequenceNo}
                productName={item.equipmentDesc}
                productCode={item.sku}
                productData={productData}
                sequenceNo={item.sequenceNo}
                pathway="offhire"
                warningMessage={getWarningMessage(item)}
              />
            )
          })}

          {showBadDataMessage.showMessage && (
            <p className="w-full py-16 text-2xl font-bold uppercase">
              {showBadDataMessage.message}
            </p>
          )}
        </ColSpanSix>
        <ColSpanSix>
          <OffHireForm
            customerId={props.customerId}
            contractId={props.contractId}
            siteAddress={siteAddress}
            contactsList={contactsForSiteBySiteId}
            contractHireStartDate={selectedContract?.hireStartDate}
            contractHireEndDate={selectedContract?.hireEndDate}
            handleOffHireSuccess={handleOffHireSuccess}
          />
        </ColSpanSix>
      </GridSystem>
      <OffhireCollectionChargeModal
        contract={selectedContract}
        collectionCharge={collectionChargeDetails.collectionCharge}
        isModalOpen={isCollectionChargeModalOpen}
        isPartial={collectionChargeDetails.isPartial}
        suggestedCollectionChargeAmount={collectionChargeDetails.suggestedCollectionChargeAmount}
        setIsModalOpen={setIsCollectionChargeModalOpen}
        onCompleted={navigateToContractPage}
      />
    </>
  )
}

export default ArrangeCollection
