import _ from "lodash"
import React, { useEffect, useState } from "react"
import { useAllKontentResourceStringData } from "../../graphql-static/use-resource-strings"
import { AuthenticatedResourcesStrings } from "../../types/enums/authenticated-resource-strings"
import { getResourceStringByid } from "../../utils/resource-string-helper"
import { AscendingArrow, DescendingArrow, InitialStateArrow } from "./icons"
import { ColumnProps, DynamicTableProps } from "./interfaces"

export const DynamicTable = ({
  columns,
  list,
  listKey,
  listLoading,
  activeFilters,
}: DynamicTableProps) => {
  const resourceStrings = useAllKontentResourceStringData()
  const [activeSort, setActiveSort] = useState<any>({
    name: "",
    type: "",
  })

  const [renderAmount, setRenderAmount] = useState<number>(20)

  const sort = (name: string) => {
    let sortDirection = "desc"
    if (activeSort.name === name && activeSort.type === sortDirection) {
      sortDirection = "asc"
    }
    setActiveSort({ name: name, type: sortDirection })
  }

  const toggleSortIcon = (toggle: string) => {
    if (toggle !== activeSort.name) return

    if (activeSort.type === "desc") {
      return <DescendingArrow />
    } else {
      return <AscendingArrow />
    }
  }

  const sortDataBeforeRender = (): Record<string, any>[] => {
    return _.orderBy(list, [activeSort.name], [activeSort.type])
  }

  useEffect(() => {
    setRenderAmount(20)
  }, [activeFilters])

  return (
    <>
      <p className="pb-2 font-bold">
        Showing {renderAmount > list.length ? list.length : renderAmount} of{" "}
        {list.length} results
      </p>
      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-300">
            <tr>
              {columns.map(
                ({ id, name, className, renderHeader, preventSort }: ColumnProps) => {
                  return (
                    <th
                      key={id}
                      scope="col"
                      className="px-4 py-3 text-xs font-medium tracking-wider text-left text-black-900"
                    >
                      {preventSort !== true && (
                        <button
                          className={`${className} flex items-center font-bold text-sm`}
                          onClick={() => sort(id)}
                        >
                          {renderHeader ? renderHeader() : <>{name}</>}
                          {name !== undefined && activeSort.name !== id && (
                            <InitialStateArrow />
                          )}
                          {toggleSortIcon(id)}
                        </button>
                      )}
                      {preventSort === true && name !== undefined && (
                        <span
                          className={`${className} flex items-center font-bold text-sm`}
                        >
                          {name}
                        </span>
                      )}
                    </th>
                  )
                }
              )}
            </tr>
          </thead>
          <tbody>
            {listLoading && (
              <tr className="px-4 py-2 sm:px-6 lg:px-8 min-h-[52px] text-center">
                <td colSpan={100}>
                  <h2 className="w-full text-4xl font-bold uppercase text-brandOutlineGrey col-span-[100%] py-16">
                    loading...
                  </h2>
                </td>
              </tr>
            )}
            {!listLoading && !list.length ? ( //Message to be displayed before performing initial search.
              <tr className="px-4 py-2 sm:px-6 lg:px-8 min-h-[52px] text-center">
                <td colSpan={100}>
                  <h2 className="w-full text-4xl font-bold uppercase text-brandOutlineGrey col-span-[100%] py-16">
                    {getResourceStringByid(
                      resourceStrings,
                      AuthenticatedResourcesStrings.INTERNAL_AUTHENTICATED_CXDASHBOARD_CUSTOMERSEARCH_GRID_PRESEARCHMESSAGE
                    )}
                  </h2>
                </td>
              </tr>
            ) : (
              //Search Grid after initial search.
              sortDataBeforeRender()
                .slice(
                  0,
                  renderAmount > list.length ? list.length : renderAmount
                )
                .map(item => {
                  return (
                    <tr
                      key={item[listKey]}
                      className="even:bg-gray-100 odd:bg-white"
                    >
                      {columns.map(({ id, valueKeys, className, renderCell, wrapText }) => {
                        const classNames = `items-center px-4 py-4 text-sm text-black-900${wrapText ? "" : " whitespace-nowrap"}${className ? ` ${className}`: ""}`
                        if (renderCell) {
                          const values = valueKeys.map(key => item[key])
                          return (
                            <td
                              key={id}
                              className={classNames}
                            >
                              {renderCell(values, item[listKey])}
                            </td>
                          )
                        } else {
                          const values = valueKeys
                            .map(key => item[key])
                            .join(", ")

                          return (
                            <td
                              key={id}
                              className={classNames}
                            >
                              {values}
                            </td>
                          )
                        }
                      })}
                    </tr>
                  )
                })
            )}
          </tbody>
        </table>
      </div>

      <div className="flex justify-center py-14">
        {renderAmount < list.length && (
          <button
            className="uppercase px-24 py-2.5 border-2 text-m 2xl:text-sm 4xl:text-md font-medium rounded-full hover:bg-filteringPillsHover hover:text-white transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2 text-black"
            onClick={() => setRenderAmount(renderAmount + 20)}
          >
            LOAD MORE
          </button>
        )}
      </div>
    </>
  )
}
