import { useMsal } from "@azure/msal-react"
import React, { Fragment, useEffect, useState } from "react"
import { Dialog, Transition } from "@headlessui/react"
import AlertMessage from "../alert-message"
import { submitSiteSurveyCompletion } from "../../middleware/middleware-layer"

export interface ISiteSurveyCompletionModalProps {
  isOpen: boolean
  setIsOpen: (bool: boolean) => void
  contract: Record<string, any> | null
  handleSiteSurveyCompleted: () => void
}

export const SiteSurveyCompletionModal = (props: ISiteSurveyCompletionModalProps) => {
    const [confirmButtonEnabled, setConfirmButtonEnabled] = useState<boolean>(false)
    const { accounts, instance } = useMsal()

    const initialSiteSurveyCompletionFormValues = {
        siteSurveyReport: "",
    }

    const [siteSurveyCompletionFormValues, setSiteSurveyCompletionFormValues] = useState<Record<string, any>>(initialSiteSurveyCompletionFormValues)

    const initialCompleteSiteSurveyResult = {
        hasResult: false,
        status: "success",
        messages: [],
    }

    const [completeSiteSurveyResult, setCompleteSiteSurveyResult] = useState<Record<string, any>>(initialCompleteSiteSurveyResult)

    const handleBreakdownFormValueChange = (
        key: string,
        value: string | boolean | Record<string, any>
      ) => {
        setSiteSurveyCompletionFormValues({
          ...siteSurveyCompletionFormValues,
          [key]: value,
        })
      }

    function closeModal() {
        clearValues()
        props.setIsOpen(false)
    }

    const onConfirm = async () => {
        setConfirmButtonEnabled(false)

        submitSiteSurveyCompletion(
            accounts,
            instance,
            props.contract?.contractId,
            siteSurveyCompletionFormValues.siteSurveyReport,
        )
            .then((result: Record<string, any>) => {
                const {
                    success,
                    errorMessage,
                } = result?.data?.internalSubmitSiteSurveyCompletion

                if (!success) {
                    setCompleteSiteSurveyResult({
                        hasResult: true,
                        status: "danger",
                        messages: [
                            "Unable to complete the site survey.",
                            errorMessage
                        ]
                    })
                } else {
                    props.handleSiteSurveyCompleted()
                    closeModal()
                }
            })
            .catch(err => {
                console.log(err)
                setCompleteSiteSurveyResult({
                    hasResult: true,
                    status: "danger",
                    messages: [
                        "There was a problem.",
                        err
                    ]
                })
            })
    }

    const clearValues = () => {
        setSiteSurveyCompletionFormValues(initialSiteSurveyCompletionFormValues)
        setCompleteSiteSurveyResult(initialCompleteSiteSurveyResult)
    }

    useEffect(() => {
        setConfirmButtonEnabled(siteSurveyCompletionFormValues.siteSurveyReport.length > 0)
    }, [siteSurveyCompletionFormValues.siteSurveyReport])

    return (
        <>
            <Transition appear show={props.isOpen} as={Fragment}>
                <Dialog
                    as="div"
                    className="relative z-10 font-normal"
                    onClose={closeModal}
                >
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>
                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex items-center justify-center min-h-full text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="w-full max-w-xl px-2 py-8 overflow-hidden align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                                    <Dialog.Title
                                        as="h3"
                                        className="text-xl font-bold leading-6 text-gray-900"
                                    >
                                        COMPLETE SITE SURVEY
                                    </Dialog.Title>
                                    <form>
                                        <div className="flex flex-col gap-2 m-4 text-left">
                                            <p className="text-sm font-bold">
                                                Notes
                                            </p>
                                            <label className="whitespace-pre-wrap leading-5">
                                                {props.contract?.siteSurveyNotes}
                                            </label>
                                        </div>
                                        <div className="flex flex-col gap-2 m-4">
                                            <p className="text-sm font-bold text-left">
                                                Report *
                                            </p>
                                            <textarea
                                                placeholder={"Report *"}
                                                className="relative w-full py-2 pl-3 pr-10 text-left bg-white border border-gray-300 rounded-md shadow-sm cursor-default resize-none sm:text-sm"
                                                maxLength={1024}
                                                value={siteSurveyCompletionFormValues.siteSurveyReport}
                                                rows={8}
                                                onChange={(e: any) => 
                                                    handleBreakdownFormValueChange("siteSurveyReport", e.target.value)
                                                }
                                            />
                                        </div>

                                        {completeSiteSurveyResult.hasResult && (
                                            <div className="m-4">
                                                <AlertMessage
                                                    mode={completeSiteSurveyResult.status}
                                                    messages={completeSiteSurveyResult.messages}
                                                    show={true}
                                                />
                                            </div>
                                        )}
                    
                                        <div className="flex flex-row justify-around mx-4 mt-8">
                                            <button
                                                onClick={e => {
                                                    e.preventDefault()
                                                    closeModal()
                                                }}
                                                className="px-12 py-2 bg-white border border-gray-500 rounded-full hover:bg-horizonhover hover:text-white hover:border-transparent sm:text-sm"
                                            >
                                                {completeSiteSurveyResult.hasResult ? "CLOSE" : "CANCEL"}
                                            </button>
                                            {!completeSiteSurveyResult.hasResult && (
                                                <button
                                                    disabled={!confirmButtonEnabled}
                                                    onClick={e => {
                                                        e.preventDefault()
                                                        onConfirm()
                                                    }}
                                                    className={confirmButtonEnabled 
                                                        ? "px-12 py-2 text-white rounded-full bg-horizonred focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 hover:bg-horizonhover hover:text-white hover:border-transparent sm:text-sm cursor-pointer"
                                                        : "px-12 py-2 bg-white border border-gray-200 text-gray-200 rounded-full sm:text-sm"
                                                    }
                                                >
                                                    COMPLETE
                                                </button>
                                            )}
                                        </div>
                                    </form>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    )
}