import moment from "moment"
import React, { useEffect, useRef, useState } from "react"
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker-cssmodules.css"

const InputDateRangePicker = ({ callback = () => null, selectedArr }: any) => {
  const [startDate, setStartDate] = useState(null)
  const [endDate, setEndDate] = useState(null)
  const isFirstRun = useRef(true)
  const isValid = (date: Date | null) => {
    return moment(date).isValid()
  }
  
  const onChange = (dates: any) => {
    const [start, end] = dates
    if (isValid(start) || start == null) {
      setStartDate(start)
    }
    if (isValid(end) || end == null) {
      setEndDate(end)
    }
  }
  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false
      return
    }
    callback(startDate, endDate)
  }, [startDate, endDate])

  return (
    <div className={"relative mb-2"}>
      <DatePicker
        // selected={selectedArr?.[0]}
        onChange={onChange}
        startDate={selectedArr?.value?.[0]}
        endDate={selectedArr?.value?.[1]}
        selectsRange
        className="relative w-full py-2 pl-3 text-left bg-white border border-gray-300 rounded-md shadow-sm cursor-default sm:text-sm"
        isClearable
        showYearDropdown
        scrollableYearDropdown
        yearDropdownItemNumber={15}
      />
    </div>
  )
}

export default InputDateRangePicker
