import _ from "lodash"
import { useState } from "react"

export const useCustomerContractsSearchFilteringMenu = (
  customerContracts: any
) => {
  // Errors state management
  const defaultErrors = {
    contact: "",
    contractID: "",
    startDateRange: "",
    endDateRange: "",
    statusDescription: "",
    siteName: "",
    staffEntered: "",
  }
  const [errors, setErrors] = useState(defaultErrors)
  const resetErrors = () => setErrors(defaultErrors)

  // State management for filter options selected values
  const defaultInputValues = {
    contact: null,
    contractID: null,
    startDateRange: [null, null],
    endDateRange: [null, null],
    statusDescription: null,
    siteName: null,
    staffEntered: null,
    filterButtonValue: null
  }
  const [filterOptionsInputValues, setFilterOptionsInputValues] =
    useState<Record<string, any>>(defaultInputValues)
  const resetFilterOptionsInputValues = () =>
    setFilterOptionsInputValues(defaultInputValues)

  const loadOptions = (key: string) => {
    let options: any = []
    try {
      switch (key) {
        case "contractReference": {
          const contracts = customerContracts || []
          options = _.orderBy(
            _.uniqBy(
              contracts.map(({ contractNo }: Record<string, any>) => ({
                label: contractNo,
                value: contractNo,
              })),
              "value"
            ),
            "value"
          )
          break
        }
        case "contact": {
          const contracts = customerContracts || []
          options = _.orderBy(
            _.uniqBy(
              contracts.map(({ contact }: Record<string, any>) => ({
                label: contact,
                value: contact,
              })),
              "value"
            ),
            "value"
          )
          break
        }
        case "siteName": {
          const contracts = customerContracts || []
          options = _.orderBy(
            _.uniqBy(
              contracts.map(({ siteName }: Record<string, any>) => ({
                label: siteName,
                value: siteName,
              })),
              "value"
            ),
            "value"
          )
          break
        }
        case "statusDescription": {
          const contracts = customerContracts || []
          options = _.orderBy(
            _.uniqBy(
              contracts.map(({ statusDescription }: Record<string, any>) => ({
                label: statusDescription,
                value: statusDescription,
              })),
              "value"
            ),
            "value"
          )
          break
        }
        case "staffEntered": {
          const contracts = customerContracts || []
          options = _.orderBy(
            _.uniqBy(
              contracts.map(({ staffEntered }: Record<string, any>) => ({
                label: staffEntered,
                value: staffEntered,
              })),
              "value"
            ),
            "value"
          )
          break
        }
        case "filterButtonValue": {
          const contracts = customerContracts || []
          options = _.orderBy(
            _.uniqBy(
              contracts.map(({ filterButtonValue }: Record<string, any>) => ({
                label: filterButtonValue,
                value: filterButtonValue,
              })),
              "value"
            ),
            "value"
          )
          break
        }
      }
      return options
    } catch (err) {
      console.log(err)
      return []
    }
  }

  const onOptionSelected = (key: string) => (value: Record<string, any>) => {
    setFilterOptionsInputValues({
      ...filterOptionsInputValues,
      [key]: value,
    })
  }

  const onDateRangeOptionSelected =
    (key: string) => (value: Record<string, any>) => {
      if (Array.isArray(value) && value.filter(Boolean).length === 0) {
        setFilterOptionsInputValues({
          ...filterOptionsInputValues,
          [key]: value,
        })
      } else {
        setFilterOptionsInputValues({
          ...filterOptionsInputValues,
          [key]: { label: key, value: value },
        })
      }
    }

  const allButton = {
    name: "All",
    title: "All",
    onClick: onOptionSelected("filterButtonValue"),
  }

  const buttons = loadOptions("filterButtonValue").map((statusType: any) => ({
    name: Object.values(statusType)[0],
    title: Object.values(statusType)[0],
    onClick: onOptionSelected("filterButtonValue"),
  }))

  const filterOptionsFields = [
    {
      id: "contractId",
      type: "select",
      label: "Contract Reference",
      placeholder: "Contract Ref.",
      options: loadOptions("contractReference"),
      handleOnChange: onOptionSelected("contractReference"),
      error: errors.contractID,
    },
    {
      id: "contact",
      type: "select",
      label: "Contact",
      placeholder: "Contact",
      options: loadOptions("contact"),
      handleOnChange: onOptionSelected("contact"),
      error: errors.contact,
    },
    {
      id: "startDateRange",
      type: "dateRange",
      label: "Start Date Range",
      placeholder: "Start Date Range",
      handleOnChange: onDateRangeOptionSelected("startDateRange"),
      error: errors.startDateRange,
    },
    {
      id: "endDateRange",
      type: "dateRange",
      label: "End Date Range",
      placeholder: "End Date Range",
      handleOnChange: onDateRangeOptionSelected("endDateRange"),
      error: errors.endDateRange,
    },
    {
      id: "siteName",
      type: "select",
      label: "Site Address",
      placeholder: "Site Address",
      options: loadOptions("siteName"),
      handleOnChange: onOptionSelected("siteName"),
      error: errors.siteName,
    },
    {
      id: "staffEntered",
      type: "select",
      label: "Entered By",
      placeholder: "Entered By",
      options: loadOptions("staffEntered"),
      handleOnChange: onOptionSelected("staffEntered"),
      error: errors.staffEntered,
    },
  ]

  const values = Object.values(filterOptionsInputValues).filter(Boolean)
  const selectedCustomerId = values[0]?.value

  return {
    filterOptionsFields,
    filterOptionsInputValues,
    selectedCustomerId,
    resetFilterOptionsInputValues,
    resetErrors,
    buttons,
    allButton,
  }
}
