import React, { Fragment, useEffect, useState } from "react"
import { Dialog, Transition } from "@headlessui/react"

export interface IAddContactEmailModal {
  handleEmailAddressEntered: (emailAddress: string) => void
  isOpen: boolean
  setIsOpen: (bool: boolean) => void
  title?: string
  subTitle?: string
}

export const AddContactEmailModal = ({
    handleEmailAddressEntered,
    isOpen,
    setIsOpen,
    title = "Add Contact Email Address",
    subTitle = 'The contact does not have an email address. Please enter their email address to continue.',
}: IAddContactEmailModal) => {
    const [ continueButtonEnabled, setContinueButtonEnabled ] = useState(false)
    const [ contactEmail, setContactEmail ] = useState("")

    function closeModal() {
      setContactEmail("")
      setIsOpen(false)
    }

    useEffect(() => {
        setContinueButtonEnabled(contactEmail.length > 0)
    }, [contactEmail])

    return (
        <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10 font-normal"
          onClose={closeModal}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full text-center">
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                >
                <Dialog.Panel className="w-full max-w-md px-2 py-8 overflow-hidden align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                  <Dialog.Title
                    as="h3"
                    className="text-xl font-bold leading-6 text-gray-900"
                  >
                    {title.toUpperCase()}
                  </Dialog.Title>
                  {subTitle && (
                    <p className="mx-4 mt-4 leading-5">{subTitle}</p>
                  )}
                  <p className="mx-4 mt-4 leading-5 font-bold">This will amend the contact which may impact other contracts.</p>
                  <form>
                    <div className="flex flex-col gap-4 mx-4 my-8">
                      <div className="flex flex-row gap-2">
                        <p className="text-sm font-bold text-left self-center">
                          Email *
                        </p>
                        <input
                          type="text"
                          placeholder="Email *"
                          className="relative grow py-2 pl-3 pr-10 text-left bg-white border border-gray-300 rounded-md shadow-sm cursor-default resize-none h-9 sm:text-sm"
                          onChange={(value: any) =>
                            setContactEmail(value.target.value)
                          }
                        />
                      </div>
                    </div>
                    <div className="flex flex-row gap-2 mx-4">
                      <div className="flex flex-1">
                        <button
                          onClick={e => {
                            e.preventDefault()
                            closeModal()
                          }}
                          className="w-full px-4 py-2 bg-white border border-gray-500 rounded-full hover:bg-horizonhover hover:text-white hover:border-transparent sm:text-sm"
                        >
                          Cancel
                        </button>
                      </div>
                      <div className="flex flex-1">
                        <button
                          onClick={e => {
                            e.preventDefault()
                            handleEmailAddressEntered(contactEmail)
                          }}
                          disabled={!continueButtonEnabled}
                          className={continueButtonEnabled 
                            ? "w-full px-4 py-2 text-white rounded-full bg-horizonred focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 hover:bg-horizonhover hover:text-white hover:border-transparent sm:text-sm cursor-pointer"
                            : "w-full px-4 py-2 bg-white border border-gray-200 text-gray-200 rounded-full sm:text-sm"
                          }
                        >
                          Continue
                        </button>
                      </div>
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    )
}