import React, { useEffect, useState } from "react"
import { useMsal } from "@azure/msal-react";
import { useCustomerContext } from "../../store";
import { NewContractButton } from "../../../../../new-contract-button";
import { LoadingFeedback } from "../../../../../loading-feedback";
import { getEquipmentList } from "../../../../../../middleware/middleware-layer";
import { DynamicTable } from "../../../../../dynamic-table";
import { DynamicFilteringMenu } from "../../../../../dynamic-filtering-menu";
import _ from "lodash";
import { useResourceStrings } from "../../../use-resource-strings";


export default function HireRates(props: any) {
    const { customer } = useCustomerContext()
    const { instance, accounts } = useMsal()

    const { clearButtonTitle } = useResourceStrings()

    const [isLoading, setIsLoading] = useState(true)
    const [equipmentList, setEquipmentList] = useState<Record<string, any>[]>([])
    const [filteredEquipmentList, setFilteredEquipmentList] = useState<Record<string, any>[]>([])
    const [selectedButton, setSelectedButton] = useState("all")

    const defaultInputValues = {
        pricingEquipmentClass: null,
        pcode: null,
        equipmentDescription: null,
        filterButtonValue: null,
    }

    const [filterOptionsInputValues, setFilterOptionsInputValues] = useState<Record<string, any>>(defaultInputValues)

    const resetFilterOptionsInputValues = () => {
        setSelectedButton("all")
        setFilterOptionsInputValues(defaultInputValues)
    }

    const onOptionSelected = (key: string) => (value: Record<string, any>) => {
        setFilterOptionsInputValues({
          ...filterOptionsInputValues,
          [key]: value,
        })
    }

    const containsAll = (obj1: any, obj2: any) => {
        return _.every(Object.keys(obj2), function (key: any) {
            return _.includes(obj1, obj2[key].value)
        })
      }

    useEffect(() => {
        const filterOptionsValues = Object.values(filterOptionsInputValues).map((value) => {
            return value
          }
        )
        setFilteredEquipmentList(
            equipmentList.filter((equipment: any) => {
                return containsAll(equipment, filterOptionsValues.filter(Boolean))
            })
        )
    }, [filterOptionsInputValues])

    const renderPricingTypeCell = (values?: Record<string, any | string>) => {
        if (values && values[0] == null) {
            return
        }
        const className = "block text-sm text-center px-2 py-1 text-white rounded"
        if (values && values[0] === "PA") {
            return (
                <label className={`${className} bg-teal-600`}>Agreement</label>
            )
        }
        return (
            <label className={`${className} bg-rose-600`}>List</label>
        )
    }

    const renderCurrencyCell = (values?: Record<string, any | string>) => {
        if (values && values[0] == null) {
            return
        }
        const parsedValue = parseFloat(values && values[0])
        if (isNaN(parsedValue) || parsedValue <= 0) {
            return
        }
        return `£${parsedValue.toFixed(2)}`
    }

    const renderMultiplicationCurrencyCell = (values?: Record<string, any | string>) => {
        if (values && (values[0] == null || values.length < 2)) {
            return
        }
        const parsedValue0 = parseFloat(values && values[0])
        const parsedValue1 = parseFloat(values && values[1])
        if ((isNaN(parsedValue0) || parsedValue0 <= 0) || (isNaN(parsedValue1) || parsedValue1 <= 0)) {
            return
        }
        return `£${(parsedValue0 * parsedValue1).toFixed(2)}`
    }

    const columns = [
        {
            id: "pricingType",
            name: "Source",
            valueKeys: ["pricingType"],
            className: "",
            renderCell: renderPricingTypeCell
        },
        {
            id: "pricingEquipmentClass",
            name: "Code",
            valueKeys: ["pricingEquipmentClass"],
            className: "text-left",
        },
        {
            id: "pcode",
            name: "Stock No.",
            valueKeys: ["pcode"],
            className: "text-left",
        },
        {
            id: "equipmentDescription",
            name: "Description",
            valueKeys: ["equipmentDescription"],
            className: "text-left",
            wrapText: true,
        },
        {
            id: "pricing1Day",
            name: "1 Day",
            valueKeys: ["pricing1Day"],
            className: "text-center",
            renderCell: renderCurrencyCell
        },
        {
            id: "pricing2Day",
            name: "2 Day",
            valueKeys: ["pricing2Day"],
            className: "text-center",
            renderCell: renderCurrencyCell
        },
        {
            id: "pricing3Day",
            name: "3 Day",
            valueKeys: ["pricing3Day"],
            className: "text-center",
            renderCell: renderCurrencyCell
        },
        {
            id: "pricingWeek",
            name: "5 Week",
            valueKeys: ["pricingWeek"],
            className: "text-center",
            renderCell: renderCurrencyCell
        },
        {
            id: "pricingMonth",
            name: "Month",
            valueKeys: ["pricingMonth"],
            className: "text-center",
            renderCell: renderCurrencyCell
        },
        {
            id: "fuelName",
            name: "Fuel",
            valueKeys: ["fuelName"],
            className: "text-left",
            wrapText: true,
        },
        {
            id: "fuelQuantity",
            name: "Fuel Qty",
            valueKeys: ["fuelQuantity"],
            className: "text-center",
        },
        {
            id: "fuelChargePerUnit",
            name: "Fuel Charge/Unit",
            valueKeys: ["fuelChargePerUnit"],
            className: "text-center",
            renderCell: renderCurrencyCell
        },
        {
            id: "fuelTotal",
            name: "Fuel Total",
            valueKeys: ["fuelQuantity", "fuelChargePerUnit"],
            className: "text-center",
            renderCell: renderMultiplicationCurrencyCell
        },
    ]

    const filterOptionsFields = [
        {
            id: "pricingEquipmentClass",
            type: "select",
            label: "Code",
            placeholder: "Code",
            options: _.orderBy(
                _.uniqBy(
                  equipmentList.map(({ pricingEquipmentClass }: Record<string, any>) => ({
                    label: pricingEquipmentClass,
                    value: pricingEquipmentClass,
                  })),
                  "value"
                ),
                "value"
              ),
            handleOnChange: onOptionSelected("pricingEquipmentClass"),
        },
        {
            id: "pcode",
            type: "select",
            label: "Stock No.",
            placeholder: "Stock No.",
            options: _.orderBy(
                _.uniqBy(
                  equipmentList.map(({ pcode }: Record<string, any>) => ({
                    label: pcode,
                    value: pcode,
                  })),
                  "value"
                ),
                "value"
              ),
            handleOnChange: onOptionSelected("pcode"),
        },
        {
            id: "equipmentDescription",
            type: "select",
            label: "Description",
            placeholder: "Description",
            options: _.orderBy(
                _.uniqBy(
                  equipmentList.map(({ equipmentDescription }: Record<string, any>) => ({
                    label: equipmentDescription,
                    value: equipmentDescription,
                  })),
                  "value"
                ),
                "value"
              ),
            handleOnChange: onOptionSelected("equipmentDescription"),
        },
    ]

    const allButton = {
        name: "all",
        title: "All",
        onClick: onOptionSelected("pricingType"),
    }

    const buttons = [
        {
            name: "PA",
            title: "Agreement",
            onClick: onOptionSelected("pricingType"),
        },
        {
            name: "LP",
            title: "List",
            onClick: onOptionSelected("pricingType"),
        }
    ]

    useEffect(() => {
        getEquipmentList(accounts, instance, props.customerId)
            .then((results: any) => {
                const equipmentListByCustomerId =
                    results?.data?.internalGetEquipmentListByCustomerId?.equipments || []
                setEquipmentList(equipmentListByCustomerId.filter((equipment: any) => {
                    // Remove the gas charge
                    return equipment.pcode !== "GASCHARGEADD"
                }))
            })
            .catch(err => {
                console.log(err)
            })
            .finally(() => {
                
                setIsLoading(false)
            })
    }, [])

    useEffect(() => {
        setFilteredEquipmentList(equipmentList)
    }, [equipmentList])

    return props.customerId ? (
        <>
            <div className="flex flex-row justify-end mb-6">
                <h2 className="flex-1 inline-flex mt-8 mb-6 mr-6 text-3xl font-bold uppercase">
                    {customer?.customerName && customer?.customerName?.toUpperCase()}
                </h2>
                <NewContractButton 
                    accounts={accounts}
                    className="mt-8"
                    customer={customer}
                    instance={instance}
                />
            </div>
            <LoadingFeedback
                showSpinner={isLoading}
                translucentBackground={true}
            />
            <DynamicFilteringMenu
                clearButtonTitle={clearButtonTitle}
                filterOptionsFields={filterOptionsFields}
                filterOptionsInputValues={filterOptionsInputValues}
                clearFilters={resetFilterOptionsInputValues}
                resetErrors={() => {}}
                topFilteringButtons={buttons}
                topFilteringTitle={"Currently Viewing"}
                setSelectedButton={setSelectedButton}
                selectedButton={selectedButton}
                allFilterButton={allButton}
            />
            <DynamicTable
                columns={columns}
                list={filteredEquipmentList}
                listKey="pcode"
                listLoading={isLoading}
            />
        </>
    ) : null
}