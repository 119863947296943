import React, { Fragment } from "react"
import { Dialog, Transition } from "@headlessui/react"
import { Button } from "../button"

export interface IAdditionalTransportChargesItemsModalProps {
    isOpen: boolean
    setIsOpen: (bool: boolean) => void
    tier: number,
    items: Record<string, any> | null
}

export const AdditionalTransportChargesItemsModal = (props: IAdditionalTransportChargesItemsModalProps) => {
    return (
        <>
            <Transition appear show={props.isOpen} as={Fragment}>
                <Dialog
                    as="div"
                    className="relative z-10 font-normal"
                    onClose={() => props.setIsOpen(false)}
                >
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>
                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex items-center justify-center min-h-full text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="px-8 py-8 overflow-hidden align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                                    <Dialog.Title
                                        as="h3"
                                        className="text-xl font-bold leading-6 text-gray-900"
                                    >
                                        {`CATEGORY ${props.tier} HIRE ITEMS`}
                                    </Dialog.Title>
                                    
                                        {props.items && props.items.length > 0 && (
                                            <div className="mt-4 mx-8 max-h-[50vh] overflow-y-auto">
                                                <table className="min-w-full divide-y divide-gray-200 text-base">
                                                    <thead>
                                                        <tr className="text-left font-bold">
                                                            <th className="p-2 sticky top-0 bg-gray-300">Equipment Code</th>
                                                            <th className="p-2 sticky top-0 bg-gray-300">Description</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {props.items.map((item: any, index: number) => (
                                                            <tr 
                                                                key={`category-${props.tier}-item-${index}`}
                                                                className="text-left align-top even:bg-gray-100 odd:bg-white leading-5"
                                                            >
                                                                <td className="p-2">{item.code}</td>
                                                                <td className="p-2">{item.description}</td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        )}
                                        {(!props.items || props.items.length === 0) && (
                                            <div className="mt-4 mx-8">
                                                <p>{`There are no hire items with category ${props.tier} transport charges.`}</p>
                                            </div>
                                        )}
                                    <div className="flex flex-col items-center justify-center mt-8">
                                        <Button outline className="mr-3" onClick={() => props.setIsOpen(false)}>
                                            Close
                                        </Button>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                </Dialog>
            </Transition>
        </>
    )
}