import { useAllKontentResourceStringData } from "../../../../../graphql-static/use-resource-strings"

import { AuthenticatedResourcesStrings } from "../../../../../types/enums/authenticated-resource-strings"
import { getResourceStringByid } from "../../../../../utils/resource-string-helper"

export const useResourceStringsSideNavigation = () => {
  const resourceStrings = useAllKontentResourceStringData()

  const customerSearchItem = getResourceStringByid(
    resourceStrings,
    AuthenticatedResourcesStrings.INTERNAL_AUTHENTICATED_CXDASHBOARD_CUSTOMERSEARCH_SIDE_NAVIGATION
  )

  const customerDetailsItem = getResourceStringByid(
    resourceStrings,
    AuthenticatedResourcesStrings.INTERNAL_AUTHENTICATED_CXDASHBOARD_CUSTOMERDETAILS_SIDE_NAVIGATION
  )

  const outOnHireItem = getResourceStringByid(
    resourceStrings,
    AuthenticatedResourcesStrings.INTERNAL_AUTHENTICATED_CXDASHBOARD_OUTONHIRE_SIDE_NAVIGATION
  )

  const newContractsItem = getResourceStringByid(
    resourceStrings,
    AuthenticatedResourcesStrings.INTERNAL_AUTHENTICATED_CXDASHBOARD_NEWCONTRACTS_SIDE_NAVIGATION
  )

  return {
    customerSearchItem,
    customerDetailsItem,
    outOnHireItem,
    newContractsItem,
  }
}
