import _ from "lodash"
import React, { useEffect, useState } from "react"
import { DynamicTable } from "../../../../../../dynamic-table"
import { useResourceStringsNewContracts } from "../use-resource-strings"
import {Loader} from "../../../../../../loading-feedback/loader";

export interface CustomerContractsProps {
  customerContracts: any
  loading: boolean
  columns: any
}

export const CustomerContracts = ({
  customerContracts,
  loading,
  columns,
}: CustomerContractsProps) => {
  const { noContracts } = useResourceStringsNewContracts()

  const [filteredContracts, setFilteredContracts] = useState()

  useEffect(() => {
    setFilteredContracts(customerContracts)
  }, [customerContracts])

  return loading ? (
    <section className="z-20 flex items-center justify-center h-[200px] mt-[40px]">
      <Loader />
    </section>
  ) : customerContracts?.length ? (
    <>
      <DynamicTable
        columns={columns}
        listKey="contractNo"
        list={filteredContracts ? filteredContracts : customerContracts}
      />
    </>
  ) : (
    <div>
      <h2 className="w-full py-16 text-2xl font-bold uppercase">
        {noContracts}
      </h2>
    </div>
  )
}
