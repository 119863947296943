import React, { useEffect, useState } from "react"
import { Modal } from "../modal"
import CurrencyInput from "react-currency-input-field"
import AlertMessage from "../alert-message"
import { useMsal } from "@azure/msal-react"
import { submitCollectionChargeChange } from "../../middleware/middleware-layer"

export interface IOffhireCollectionChargeModalProps {
    contract: Record<string, any>
    isPartial: boolean
    collectionCharge: any
    suggestedCollectionChargeAmount: number
    isModalOpen: boolean
    setIsModalOpen: (value: boolean) => void
    onCompleted: () => void
}

export const OffhireCollectionChargeModal = (props: IOffhireCollectionChargeModalProps) => {
    const { accounts, instance } = useMsal()
    const [submitButtonEnabled, setSubmitButtonEnabled] = useState<boolean>(false)
    const [charge, setCharge] = useState<string>("")
    const [isChargeBelowSuggested, setIsChargeBelowSuggested] = useState<boolean>(false)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [isBadSubmit, setIsBadSubmit] = useState(false)

    const onSubmitChangeCharge = async () => {
        setIsSubmitting(true)

        const { sequenceNo, equipmentCategory, stockNumber} = props.collectionCharge

        try {
            await submitCollectionChargeChange(
                accounts, 
                instance, 
                props.contract.contractId,
                sequenceNo,
                equipmentCategory,
                stockNumber,
                charge,
            )
            .then((result) => {
                setIsSubmitting(false)
                if (result.contractId === "") {
                    setIsBadSubmit(true)
                } else {
                    closeModal()
                }
            })
        } catch (error) {
            console.log(error)
            setIsSubmitting(false)
            setIsBadSubmit(true)
        }
    }

    const closeModal = () => {
        props.setIsModalOpen(false)
        props.onCompleted()
    }

    useEffect(() => {
        setCharge((+props.suggestedCollectionChargeAmount).toFixed(2))
    }, [props.suggestedCollectionChargeAmount])

    useEffect(() => {
        setSubmitButtonEnabled(charge !== "")
        setIsChargeBelowSuggested(charge !== "" && +charge < props.suggestedCollectionChargeAmount)
    }, [charge])

    return (
        <Modal 
            title={`${props.isPartial ? "PARTIAL" : ""} COLLECTION CHARGE`} 
            isOpen={props.isModalOpen} 
            closeModal={closeModal}
        >
            <form>
                <div className="flex flex-col gap-4 m-4">
                    <div>
                        <p>{`The applied ${props.isPartial ? "partial " : ""}collection charge was £${(+props.collectionCharge.chargeAmount).toFixed(2)}.`}</p>
                        <p className="leading-5 mt-4">
                            {`The suggested ${props.isPartial ? "partial " : ""}collection charge based on the items being offhired is £${(+props.suggestedCollectionChargeAmount).toFixed(2)}.`}
                        </p>
                    </div>
                </div>

                <div className="flex flex-col gap-2 m-4">
                    <div className="flex flex-row items-center justify-center">
                        <div className="flex flex-col mr-4">
                            <label>Change charge to:</label>
                        </div>
                        <div className="flex flex-col">
                            <CurrencyInput
                                id="updatedCharge"
                                prefix="£"
                                value={charge}
                                decimalsLimit={2}
                                allowNegativeValue={false}
                                onValueChange={(value = "") => {
                                    setCharge(value)
                                }}
                                placeholder="Enter updated charge"
                                className="relative py-2 pl-3 pr-2 text-left bg-white border border-gray-300 rounded-md shadow-sm cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                autoComplete="off"
                            />
                        </div>
                    </div>
                    {isChargeBelowSuggested && (
                        <div className="flex flex-row justify-center">
                            <label className="text-yellow-600 font-semibold">NOTE: Updated charge is below suggested charge!</label>
                        </div>
                    )}
                </div>

                <AlertMessage
                    mode="warning"
                    messages={[
                        "Submitting..."
                    ]}
                    animate
                    show={isSubmitting}
                />

                <AlertMessage
                    show={isBadSubmit}
                    mode="danger"
                    messages={[
                    "There was a problem when submitting the change.",
                    `The ${props.isPartial ? "partial ": ""} charge may not have been set correctly.`,
                    "Please check the collection charge is correct."
                    ]}
                />

                <div className="flex flex-row gap-4 mx-4 mt-8">
                    <div className="flex flex-1">
                        <button
                            onClick={e => {
                                e.preventDefault()
                                closeModal()
                            }}
                            className="w-full px-4 py-2 bg-white border border-gray-500 rounded-full hover:bg-horizonhover hover:text-white hover:border-transparent sm:text-sm"
                        >
                            CANCEL
                        </button>
                    </div>
                    <div className="flex flex-1">
                        <button
                            onClick={e => {
                                e.preventDefault()
                                onSubmitChangeCharge()
                            }}
                            disabled={!submitButtonEnabled || isSubmitting}
                            className={submitButtonEnabled && !isSubmitting
                                ? "w-full px-4 py-2 text-white rounded-full bg-horizonred focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 hover:bg-horizonhover hover:text-white hover:border-transparent sm:text-sm cursor-pointer"
                                : "w-full px-4 py-2 bg-white border border-gray-200 text-gray-200 rounded-full sm:text-sm"
                            }
                        >
                            SUBMIT
                        </button>
                    </div>
                </div>
            </form>
        </Modal>
    )
}