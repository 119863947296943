import React from "react"

const RatesIcon = () => {
    return (
        <svg 
            className="h-[40px] w-[40px]"
            viewBox="-6.4 -6.4 76.80 76.80" 
            xmlns="http://www.w3.org/2000/svg" 
            strokeWidth="2" 
            stroke="#3A3A3A" 
            fill="none">
                <g id="SVGRepo_iconCarrier">
                    <circle cx="32" cy="32" r="38" />
                    <path d="M41.72,45.18H23.3a.14.14,0,0,1-.11-.23c1.26-1.35,7.28-8.25,5-14.46-2.57-6.92-.91-17.91,11.52-12.8" strokeLinecap="round"/>
                    <line x1="20.39" y1="30.67" x2="37.58" y2="30.67" strokeLinecap="round"/>
                </g>
            </svg>
    )
}

export default RatesIcon