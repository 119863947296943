import React, { useEffect, useState } from "react"
import Moment from "react-moment"
import {
  OffHireOrderIcon,
  PartialOffHireIcon,
} from "../../../../../../dynamic-table/icons"
import { useResourceStringsOutOnHire } from "./use-resource-strings"
import { getCustomerWithContractsByCustomerId } from "../../../../../../../middleware/middleware-layer"
import { useMsal } from "@azure/msal-react"

import { CustomerContracts } from "./customer-contract-search-filtering-menu"
import { useCustomerContext } from "../../../store"
import { Link, navigate } from "gatsby"
import { classNames } from "../../../../../../../utils/classNames"
import { NewContractButton } from "../../../../../../new-contract-button"
import moment from "moment"

export default function OutOnHire(props: any) {
  const { customer, setOffHireItems } = useCustomerContext()
  const {
    contractNo,
    status,
    endDate,
    startDate,
    productOrderNo,
    site,
    contact,
    offHireOrderButton,
    partialOffHireButton,
  } = useResourceStringsOutOnHire()
  const { instance, accounts } = useMsal()
  const [customerContracts, setCustomerContracts] = useState([])
  const [loading, setLoading] = useState(true)
  const [noResponse, setNoResponse] = useState(false)

  useEffect(() => {
    // contracts data for the specific customer
    getCustomerWithContractsByCustomerId(accounts, instance, props.customerId)
      .then((results: any) => {
        setCustomerContracts(
          results?.data?.internalGetContractsByCustomerId?.contracts
        )
        setLoading(false)
      })
      .catch(() => setNoResponse(true))
  }, [])

  const columns = [
    {
      id: "contractNo",
      name: contractNo,
      valueKeys: ["contractNo", "contractId"],
      className: "",
      renderCell: (values?: Record<string, any | string>) => {
        const contractNo = values?.[0] || ""
        const contractId = values?.[1] || ""
        return (
          <Link
            to={`/cx-dashboard/customer/${props.customerId}/contracts/${contractId}`}
          >
            {contractNo}
          </Link>
        )
      },
    },
    {
      id: "statusDescription",
      name: status,
      valueKeys: ["statusDescription"],
      className: "",
    },
    {
      id: "hireStartDate",
      name: startDate,
      valueKeys: ["hireStartDate"],
      className: "",
      renderCell: (values?: Record<string, any | string>) => {
        const valueString = (values && values.join(", ")) || ""
        return <Moment format="DD-MM-YYYY">{valueString}</Moment>
      },
    },
    {
      id: "plannedEndDate",
      name: endDate,
      valueKeys: ["plannedEndDate"],
      className: "",
      renderCell: (values?: Record<string, any | string>) => {
        const valueStringMoment = moment((values && values.join(", ")) || "")
        return valueStringMoment.isValid() ? valueStringMoment.format("DD-MM-YYYY") : ""
      },
    },
    {
      id: "orderNo",
      name: productOrderNo,
      valueKeys: ["orderNo"],
      className: "",
    },
    {
      id: "siteName",
      name: site,
      valueKeys: ["siteName"],
      className: "",
      wrapText: true,
    },
    {
      id: "items",
      name: "Items",
      valueKeys: ["itemsList"],
      className: "",
      renderCell: (itemsList? : Record<string, any | string>) => {
        const items = itemsList?.toString().split(',')
        return (
          <div>
            {items?.map((item, index) => (
              <span key={`items-list-${index}`}>
                {item}
                <br/>
              </span>
            ))}
          </div>
        )
      },
      wrapText: true,
      preventSort: true,
    },
    {
      id: "contact",
      name: contact,
      valueKeys: ["contact"],
      className: "",
    },
    {
      id: "partialOffHire",
      valueKeys: ["contractId"],
      className: "",
      renderCell: (contractId?: Record<string, any | string>) => {
        return (
          <Link
            className="w-[148px] h-[36px] bg-white rounded-full border-[1px] border-black flex justify-center items-center font-bold"
            to={`/cx-dashboard/customer/${props.customerId}/contracts/${contractId?.[0]}`}
          >
            <PartialOffHireIcon />
            {partialOffHireButton}
          </Link>
        )
      },
    },
    {
      id: "offHireOrder",
      valueKeys: ["contractId", "statusDescription"],
      className: "",
      renderCell: (values?: Record<string, any | string>) => {
        return (
          <button
            className={classNames(
              values?.[1] === "Off Hire Pending" && "cursor-not-allowed",
              "w-[148px] h-[36px] bg-white rounded-full border-[1px] border-black flex justify-center items-center font-bold"
            )}
            onClick={() => {
              navigate(
                `/cx-dashboard/customer/${props.customerId}/contracts/${values?.[0]}/arrange-collection`
              )
              setOffHireItems([])
            }}
            disabled={values?.[1] === "Off Hire Pending"}
          >
            <OffHireOrderIcon />
            {offHireOrderButton}
          </button>
        )
      },
    },
  ]

  return (
    <div>
      <div className="flex flex-row justify-end">
        <h2 className="flex-1 inline-flex mt-8 mb-6 mr-6 text-3xl font-bold uppercase">
          {customer?.customerName && customer?.customerName?.toUpperCase()}
        </h2>
        <NewContractButton 
          accounts={accounts}
          className="mt-8"
          customer={customer}
          instance={instance}
        />
      </div>
      {!noResponse ? (
        customer?.customerName && (
          <>
            <CustomerContracts
              customerContracts={customerContracts}
              loading={loading}
              columns={columns}
            />
          </>
        )
      ) : (
        <div>
          <h2 className="w-full py-16 text-2xl font-bold uppercase">
            Issue fetching data
          </h2>
        </div>
      )}
    </div>
  )
}
