import React from "react"
import { AlertBanner } from "../alert-banner"
import moment from "moment"

interface HireInsuranceStatusProps {
  hasInsurance: boolean | false
  insuranceRenewalDate: Date
  classNames?: string
}

export const HireInsuranceStatus = (props: HireInsuranceStatusProps) => {
    if (!!(props.hasInsurance) && props.insuranceRenewalDate) {
        const renewalDate = moment(props.insuranceRenewalDate)

        if (renewalDate.isValid()) {
            if (renewalDate < moment()) {
                return (
                    <div className={props.classNames}>
                        <AlertBanner
                            text={`The customer's hire insurance has expired (renewal date was ${renewalDate.format("DD/MM/YYYY")}).`}
                        />
                    </div>
                )
            }

            const warningDate = moment().add(30, "days")

            if (renewalDate < warningDate) {
                return (
                    <div className={props.classNames}>
                        <AlertBanner
                            text={`The customer's hire insurance renewal date is ${renewalDate.format("DD/MM/YYYY")}.`}
                            isWarning={true}
                        />
                    </div>
                )
            }
        }
    }

    return null
}