import React, { ReactNode } from "react"
export interface ButtonProps {
  children: ReactNode
  onClick?: (value: any) => void
}
export const CircleButton = ({ onClick, children }: ButtonProps) => {
  return (
    <button className="cursor-pointer flex pt-5" onClick={onClick}>
      <div className="inline-flex items-center text-xl px-4 py-2 pb-3 border text-gray-900 bg-white rounded-full bg-white hover:bg-horizonhover hover:text-white">
        +
      </div>
      <p className="py-4 px-2 text-sm font-bold font-medium">{children}</p>
    </button>
  )
}
