import _ from "lodash"
import React, { useEffect, useState } from "react"
import { DynamicFilteringMenu } from "../../../../../../../dynamic-filtering-menu"
import { DynamicTable } from "../../../../../../../dynamic-table"
import { useResourceStrings } from "../../../../../use-resource-strings"
import { useResourceStringsOutOnHire } from "../use-resource-strings"
import { useCustomerContractsSearchFilteringMenu } from "./use-customer-contract-search-filtering-menu"
import { Loader } from "../../../../../../../loading-feedback/loader"

export interface CustomerContractsProps {
  customerContracts: any
  loading: boolean
  columns: any
}

export const CustomerContracts = ({
  customerContracts,
  loading,
  columns,
}: CustomerContractsProps) => {
  const { customerSearchButtonTitle, filteringOptionsTitle, clearButtonTitle } =
    useResourceStrings()

  const { noContracts } = useResourceStringsOutOnHire()

  const {
    filterOptionsFields,
    filterOptionsInputValues,
    selectedCustomerId,
    resetFilterOptionsInputValues,
    resetErrors,
    buttons,
    allButton,
  } = useCustomerContractsSearchFilteringMenu(customerContracts)

  const [filteredContracts, setFilteredContracts] = useState()
  const [selectedButton, setSelectedButton] = useState("all")

  const handleClearFilters = () => {
    resetFilterOptionsInputValues()
    setSelectedButton("all")
  }

  const containsAll = (obj1: any, obj2: any) => {
    return _.every(Object.keys(obj2), function (key: any) {
      if (obj2[key].value instanceof Array) {
        //Date Ranges
        const d1 = new Date(obj2[key].value[0]).setHours(0, 0, 0, 0)
        const d2 = new Date(obj2[key].value[1]).setHours(0, 0, 0, 0)

        if (obj2[key].label == "startDateRange") {
          const sd = new Date(obj1.hireStartDate).setHours(0, 0, 0, 0)
          if (sd.valueOf() >= d1.valueOf() && sd.valueOf() <= d2.valueOf()) {
            return true
          }
        }
        if (obj2[key].label == "endDateRange") {
          const ed = new Date(obj1.plannedEndDate).setHours(0, 0, 0, 0)
          if (ed.valueOf() >= d1.valueOf() && ed.valueOf() <= d2.valueOf()) {
            return true
          }
        } else {
          return false
        }
      } else {
        //Everything else
        return _.includes(obj1, obj2[key].value)
      }
    })
  }

  useEffect(() => {
    setFilteredContracts(
      customerContracts.filter((contract: any) => {
        return containsAll(
          contract,
          Object.values(filterOptionsInputValues).filter(Boolean)
        )
      })
    )
  }, [filterOptionsInputValues])

  useEffect(() => {
    setFilteredContracts(customerContracts)
  }, [customerContracts])

  return loading ? (
    <section className="z-20 flex items-center justify-center h-[200px] mt-[40px]">
      <Loader />
    </section>
  ) : customerContracts?.length ? (
    <>
      <DynamicFilteringMenu
        buttonTitle={customerSearchButtonTitle}
        filteringOptionsTitle={filteringOptionsTitle}
        filterOptionsFields={filterOptionsFields}
        filterOptionsInputValues={filterOptionsInputValues}
        // handleSubmit={handleSearchCustomer}
        resetErrors={resetErrors}
        clearButtonTitle={clearButtonTitle}
        clearFilters={handleClearFilters}
        disableSubmit={!selectedCustomerId}
        defaultOpen
        topFilteringButtons={buttons}
        topFilteringTitle={"Currently Viewing"}
        setSelectedButton={setSelectedButton}
        selectedButton={selectedButton}
        allFilterButton={allButton}
      />
      <DynamicTable
        columns={columns}
        listKey="contractNo"
        list={filteredContracts ? filteredContracts : customerContracts}
      />
    </>
  ) : (
    <div>
      <h2 className="w-full py-16 text-2xl font-bold uppercase">
        {noContracts}
      </h2>
    </div>
  )
}
