import React, { useEffect, useState } from "react"
import Select from "react-select"
import { useCustomerContext } from "../../store"
import AlertMessage from "../../../../../alert-message"
import { Button } from "../../../../../button"
import { navigate } from "gatsby-link"
import { Loader } from "../../../../../loading-feedback/loader"
import { 
    createNewSiteSurvey, 
    getContactsByCustomerId, 
    getSitesForCustomerSavedContractsByCustomerId, 
    getSiteSurveyors 
} from "../../../../../../middleware/middleware-layer"
import { useMsal } from "@azure/msal-react"
import { transformSiteObjToOption } from "../../../../../../utils/transformSiteObjToOption"
import { IBadData, IDeliveryDetails } from "../../../../../../types/interfaces"
import { useResourceStringsNewContracts } from "../new-contracts/use-resource-strings"
import { isPostcodeExcluded } from "../../../../../../utils/postcode-exclusions-helper"
import { useAllKontentPostcodeExclusionsData } from "../../../../../../graphql-static/use-postcode-exclusions"
import { AddAmendAddressModal } from "../../../../../add-amend-address-modal"
import DateBoxField from "../../../../../date-box-field"
import { AddAmendContactModal } from "../../../../../add-amend-contact-modal"
import { transformContactObjToOption } from "../../../../../../utils/transformContactObjToOption"
import CheckboxRound from "../../../../../checkbox-round"

export default function SiteSurvey(props: any) {
    const { customer, isCustomerLoading } = useCustomerContext()
    const { instance, accounts } = useMsal()
    const excludedPostcodes = useAllKontentPostcodeExclusionsData()
    const [isSitesLoading, setIsSitesLoading] = useState<boolean>(false)
    const [isContactsLoading, setIsContactsLoading] = useState<boolean>(false)
    const [isSurveyorsLoading, setIsSurveyorsLoading] = useState<boolean>(false)
    const [deliverySites, setDeliverySites] = useState<IDeliveryDetails[]>([])
    const [deliveryContacts, setDeliveryContacts] = useState<IDeliveryDetails[]>([])
    const [excludedPostcode, setExcludedPostcode] = useState<Record<string, any>>({})
    const [isOpenAddress, setIsOpenAddress] = useState<boolean>(false)
    const [isOpenContact, setIsOpenContact] = useState<boolean>(false)
    const [isOpenConfirmAddress, setIsOpenConfirmAddress] = useState<boolean>(false)
    const [isOpenConfirmContact, setIsOpenConfirmContact] = useState<boolean>(false)
    const [canAmendAddress, setCanAmendAddress] = useState<boolean>(false)
    const [canAmendContact, setCanAmendContact] = useState<boolean>(false)
    const [isSaving, setIsSaving] = useState<boolean>(false)
    const [previousSiteAddress, setPreviousSiteAddress] = useState<Record<string, any> | null>(null)
    const [previousSiteContact, setPreviousSiteContact] = useState<Record<string, any> | null>(null)

    const { siteAddress, siteContact } = useResourceStringsNewContracts()

    const [formStatus, setFormStatus] = useState<Record<string, any>>({
        status: "",
        messages: [],
    })

    const [surveyors, setSurveyors] = useState<Record<string, any>[]>([])

    const initialSelectValue = {
        label: "",
        value: null,
    }

    const initialNewAddressState: Record<string, any> = {
        addressName: "",
        addressLine1: "",
        addressLine2: "",
        addressTown: "",
        addressCounty: "",
        addressPostcode: "",
        addressDirections: "",
        addressLat: "",
        addressLong: "",
        addressWhat3Words: "",
    }

    const initialNewContactState: Record<string, any> = {
        contactFirstName: "",
        contactSurname: "",
        contactTelephone: "",
        contactEmail: "",
      }

    const [newAddressValues, setNewAddressValues] = useState<Record<string, any>>(initialNewAddressState)
    const [newContactValues, setNewContactValues] = useState<Record<string, any>>(initialNewContactState)

    const initialFormDetailsState: Record<string, any> = {
        siteAddress: { label: "", value: null },
        siteContact: { label: "", value: null },
        surveyor: { label: "", value: null },
        onHireDate: null,
        requirements: "",
        surveyorOption: 2,
        supplier: null,
        supplierBranch: null,
        supplierContact: null,
      }

    const [formValues, setFormValues] = useState<Record<string, any>>(initialFormDetailsState)

    const [confirmAddressCTA, setConfirmAddressCTA] = useState<Record<string, any>>({
        title: "",
        CTA: "",
    })

    const [confirmContactCTA, setConfirmContactCTA] = useState<Record<string, any>>({
        title: "",
        CTA: "",
    })

    const [showBadDataMessage, setShowBadDataMessage] = useState<IBadData>({
        showMessage: false,
        message: "",
    })

    const handleFormDetailChange = (
        key: string,
        value: string | boolean | number | Record<string, any> | null
      ) => {
        let formValuesToSet = { 
            ...formValues
        }
        formValuesToSet[key] = value
        if (key === "supplier") {
            formValuesToSet.supplierBranch = null
            formValuesToSet.supplierContact = null
        }
        if (key === "supplierBranch") {
            formValuesToSet.supplierContact = null
        }
        setFormValues(formValuesToSet)
        checkPostcodeIsExcluded(formValuesToSet.siteAddress?.value?.addressPostcode || "")
      }

    const handleOnCancel = () => {
        navigate(`/cx-dashboard/customer/${props.customerId}`)
    }

    const handleOnCreate = () => {
        const { value: siteAddressData } = formValues.siteAddress || {}
        const { value: siteContactData } = formValues.siteContact || {}
        const { value: surveyorData } = formValues.surveyor || {}
        // const { value: supplierContactData } = formValues.supplierContact || {}
        // const { value: supplierData } = formValues.supplier || {}

        const siteSurvey: Record<string, any> = {
            customerId: +props.customerId,
            addressId: +siteAddressData?.addressId || null,
            contactId: +siteContactData?.contactId || null,
            siteAddressData: siteAddressData || null,
            siteContactData: siteContactData || null,
            requirements: formValues.requirements,
            onHireDate: formValues.onHireDate,
            isInternal: formValues.surveyorOption === 0,
            isPartner: formValues.surveyorOption === 1,
            surveyorId: surveyorData?.surveyorId || null,
        }

        // Kept consistent with new contracts.
        // addressId means an existing site was selected and is unchanged.
        // siteSurvey.siteAddressData is used for the surveyor notification email.
        // siteSurvey.updatedSiteAddressData is for new or updated addresses.
        if (siteAddressData?.update) {
            const clonedSiteAddressData = JSON.parse(JSON.stringify(siteAddressData))
            delete clonedSiteAddressData.update
            siteSurvey.siteAddressData = clonedSiteAddressData
            siteSurvey.updatedSiteAddressData = clonedSiteAddressData
        }

        // contactId means an existing contact was selected and is unchanged.
        // siteSurvey.siteContactData is used for the surveyor notification email.
        // siteSurvey.updatedSiteContactData is for new or updated contact.
        if (siteContactData?.update) {
            const clonedSiteContactData = JSON.parse(JSON.stringify(siteContactData))
            delete clonedSiteContactData.update
            siteSurvey.siteContactData = clonedSiteContactData
            siteSurvey.updatedSiteContactData = clonedSiteContactData
        }

        const errors = [
            "addressId",
            "contactId",
            "requirements",
            "onHireDate",
            "isInternal",
        ].reduce((agg: string[], key: string) => {
            if (key === "addressId") {
                return !siteSurvey[key] && !siteSurvey?.updatedSiteAddressData
                ? [...agg, "Site Address is required."]
                : agg
            }
            if (key === "contactId") {
                return !siteSurvey[key] && !siteSurvey?.updatedSiteContactData
                ? [...agg, "Site Contact is required."]
                : agg
            }
            if (key === "requirements") {
                if (!siteSurvey[key]) return [...agg, "Requirements are required."]
            }
            if (key === "onHireDate") {
                if (!siteSurvey[key]) return [...agg, "Survey Required Date is required."]
            }
            if (key === "isInternal") {
                if (siteSurvey.isInternal && !siteSurvey.surveyorId) return [...agg, "Surveyor is required."]
            }
            return agg
        }, [])

        setFormStatus({
            status: "error",
            messages: errors,
        })
    
        if (errors.length) {
            return null
        } else {
            setIsSaving(true)
        
            createNewSiteSurvey(accounts, instance, siteSurvey)
                .then((results: Record<string, any>) => {
                    if (results.errors?.length > 0) {
                        setFormStatus({
                            status: "error",
                            messages: [
                                "Site survey contract may not have been created.",
                                `Reason: ${results.errors[0].message}`,
                            ],
                        })
                        setIsSaving(false)
                    }
                    const contractId = results?.data?.internalCreateNewSiteSurvey?.contractId
                    if (contractId) {
                        navigate(`/cx-dashboard/customer/${props.customerId}/contracts/saved/${contractId}`)
                    }
                })
                .catch(err => {
                    console.log(err)
                    setIsSaving(false)
                })
        }
    }

    const checkPostcodeIsExcluded = (postcode: string) => {
        const isPostcodeExcludedResult = isPostcodeExcluded(excludedPostcodes, postcode)
        setExcludedPostcode(isPostcodeExcludedResult)
    }

    const decideModalLabels = () => {    
        if (newContactValues.contactId) {
            setConfirmContactCTA({
              title: "UPDATE EXISTING CONTACT",
              cta: "UPDATE CONTACT",
            })
          } else {
            setConfirmContactCTA({ title: "CREATE NEW CONTACT", cta: "ADD CONTACT" })
          }

        if (newAddressValues.addressId) {
          setConfirmAddressCTA({
            title: "UPDATE EXISTING ADDRESS",
            cta: "UPDATE ADDRESS",
          })
        } else {
          setConfirmAddressCTA({ title: "CREATE NEW ADDRESS", cta: "ADD ADDRESS" })
        }
    }

    useEffect(() => {
        if (showBadDataMessage.showMessage) {
          console.log(showBadDataMessage.message)
        }
    }, [showBadDataMessage])

    useEffect(() => {
        setIsSitesLoading(true)
        getSitesForCustomerSavedContractsByCustomerId(
            accounts,
            instance,
            props.customerId
          )
            .then((results: any) => {
              setDeliverySites(
                results?.data?.internalSitesForCustomer.map(transformSiteObjToOption)
              )
              setShowBadDataMessage({ showMessage: false, message: "" })
              setIsSitesLoading(false)
            })
            .catch(err => {
              console.log(err)
              setShowBadDataMessage({
                showMessage: true,
                message: `We could not find any site data for Customer ID ${props.customerId}`,
              })
            })
        
        setIsContactsLoading(true)
        getContactsByCustomerId(
            accounts,
            instance,
            +props.customerId
            )
            .then((results: any) => {
                setDeliveryContacts(
                    results?.data?.internalGetContactsByCustomerId.contacts.map(transformContactObjToOption)
                )
                setShowBadDataMessage({ showMessage: false, message: "" })
                setIsContactsLoading(false)
            })
            .catch(err => {
                console.log(err)
                setShowBadDataMessage({
                showMessage: true,
                message: `We could not find any site data for Customer ID ${props.customerId}`,
                })
            })

        setIsSurveyorsLoading(true)
        getSiteSurveyors(
            accounts,
            instance,
            )
            .then((results: any) => {
                setSurveyors(
                    results?.data?.internalGetSiteSurveyors.surveyors.map((surveyor: any) => {
                        return {
                            label: surveyor.surveyorName,
                            value: surveyor,
                        }
                    })
                )
                setShowBadDataMessage({ showMessage: false, message: "" })
                setIsSurveyorsLoading(false)
            })
            .catch(err => {
                console.log(err)
                setShowBadDataMessage({
                    showMessage: true,
                    message: `We could not find any site surveyors.`,
                })
            })
    }, [])

    useEffect(() => {
        if (deliverySites?.length > 0) {
          setFormValues(prevState => {
            return {
              ...prevState,
              siteAddress: deliverySites[0],
            }
          })
        }
    }, [deliverySites])

    useEffect(() => {
        if (deliveryContacts?.length > 0) {
          setFormValues(prevState => {
            return {
              ...prevState,
              siteContact: deliveryContacts[0],
            }
          })
        }
    }, [deliveryContacts])

    useEffect(() => {
        decideModalLabels()
        if (formValues?.siteAddress?.value != null) {
          setNewAddressValues(formValues.siteAddress.value)
          setCanAmendAddress(true)
          checkPostcodeIsExcluded(formValues.siteAddress.value.addressPostcode)
        } else {
          setCanAmendAddress(false)
        }
    }, [formValues?.siteAddress])

    useEffect(() => {
        decideModalLabels()
        if (formValues?.siteContact?.value != null) {
          setNewContactValues(formValues.siteContact.value)
          setCanAmendContact(true)
        } else {
          setCanAmendContact(false)
        }
    }, [formValues?.siteContact])

    if (isCustomerLoading) {
        return (
            <div className="flex flex-col items-center justify-center h-full p-44">
            <Loader />
            <p className="text-xl animate-pulse">Loading customer...</p>
            </div>
        )
    }

    if (isSitesLoading) {
        return (
          <div className="flex flex-col items-center justify-center h-full p-44">
            <Loader />
            <p className="text-xl animate-pulse">Loading customer sites...</p>
          </div>
        )
    }

    if (isContactsLoading) {
        return (
          <div className="flex flex-col items-center justify-center h-full p-44">
            <Loader />
            <p className="text-xl animate-pulse">Loading customer contacts...</p>
          </div>
        )
    }

    if (isSurveyorsLoading) {
        return (
          <div className="flex flex-col items-center justify-center h-full p-44">
            <Loader />
            <p className="text-xl animate-pulse">Loading site surveyors...</p>
          </div>
        )
    }

    return (
        <>
            <div className="flex flex-col">
                <h2 className="text-3xl font-bold uppercase">
                    {customer?.customerName && customer?.customerName?.toUpperCase()}
                </h2>
                <p className="mt-8 text-3xl font-bold uppercase">
                    New Site Survey
                </p>
            </div>
            <div className="flex flex-row w-3/4">
                <div className="flex flex-col w-full">
                    <section>
                        <h2 className="my-4 text-xl font-bold uppercase">
                            Details
                        </h2>
                        <div className="mb-5 bg-white border rounded-md">
                            <div className="flex flex-col gap-4 p-4">
                                <div className="flex flex-row w-full gap-4">
                                    <div className="flex flex-col gap-2 w-3/5">
                                        <div className="flex flex-row gap-2">
                                            <label
                                                className="text-sm font-bold truncate"
                                                htmlFor="siteAddress"
                                            >
                                                {siteAddress}
                                            </label>
                                            <button
                                                onClick={e => {
                                                    e.preventDefault()
                                                    setPreviousSiteAddress(formValues.siteAddress)
                                                    handleFormDetailChange("siteAddress", initialSelectValue)
                                                    setNewAddressValues(initialNewAddressState)
                                                    setCanAmendAddress(false)
                                                    setIsOpenAddress(true)
                                                }}
                                                className="pb-1 text-right underline bg-white sm:text-xs"
                                            >
                                                New
                                            </button>
                                            {JSON.stringify(newAddressValues) !== JSON.stringify(initialNewAddressState) &&
                                                canAmendAddress && (
                                                    <button
                                                        onClick={e => {
                                                            e.preventDefault()
                                                            setNewAddressValues(
                                                                formValues?.siteAddress?.value
                                                            )
                                                            decideModalLabels()
                                                            if (formValues?.siteAddress?.value?.addressId) {
                                                                setIsOpenConfirmAddress(true)
                                                            } else {
                                                                setIsOpenAddress(true)
                                                            }
                                                        }}
                                                        className="pb-1 text-right underline bg-white sm:text-xs"
                                                    >
                                                        Amend
                                                    </button>
                                            )}
                                            {excludedPostcode.excluded && (
                                                <div className="flex flex-row flex-grow items-center flex-row-reverse">
                                                    <label className="text-sm font-bold truncate text-red-600">Exclusion Postcode</label>
                                                </div>
                                            )}
                                        </div>
                                        <Select
                                            id="siteAddress"
                                            value={formValues.siteAddress}
                                            options={deliverySites}
                                            placeholder={siteAddress}
                                            className="text-sm"
                                            onChange={value => {
                                                handleFormDetailChange("siteAddress", value)
                                            }}
                                            isClearable
                                        />
                                    </div>
                                    <div className="flex flex-col gap-2 w-2/5">
                                        <div className="flex flex-row gap-2">
                                            <label
                                                className="text-sm font-bold truncate"
                                                htmlFor="siteContact"
                                            >
                                                {siteContact}
                                            </label>
                                            <button
                                                onClick={e => {
                                                    e.preventDefault()
                                                    setPreviousSiteContact(formValues.siteContact)
                                                    handleFormDetailChange("siteContact", initialNewContactState)
                                                    setNewContactValues(initialNewContactState)
                                                    setCanAmendContact(false)
                                                    setIsOpenContact(true)
                                                }}
                                                className="pb-1 text-right underline bg-white sm:text-xs"
                                            >
                                                New
                                            </button>
                                            {JSON.stringify(newContactValues) !==
                                                JSON.stringify(initialNewContactState) &&
                                                canAmendContact && (
                                                <button
                                                    onClick={e => {
                                                        e.preventDefault()
                                                        setNewContactValues(
                                                            formValues?.siteContact?.value
                                                        )
                                                        decideModalLabels()
                                                        if (formValues?.siteContact?.value?.contactId) {
                                                            setIsOpenConfirmContact(true)
                                                        } else {
                                                            setIsOpenContact(true)
                                                        }
                                                    }}
                                                    className="pb-1 text-right underline bg-white sm:text-xs"
                                                >
                                                    Amend
                                                </button>
                                            )}
                                        </div>

                                        <Select
                                            id="siteContact"
                                            value={formValues.siteContact}
                                            onChange={value =>
                                                handleFormDetailChange("siteContact", value)
                                            }
                                            options={deliveryContacts}
                                            placeholder={siteContact}
                                            isClearable
                                            className="text-sm"
                                        />
                                    </div>
                                </div>
                                <div className="flex flex-row w-full gap-4">
                                    <div className="flex flex-col gap-2 w-3/5">
                                        <label
                                            className="text-sm font-bold truncate"
                                            htmlFor="requirements"
                                        >
                                            Requirements
                                        </label>
                                        <textarea
                                            placeholder={"Requirements"}
                                            value={formValues.requirements}
                                            className="flex flex-grow w-full py-2 pl-3 pr-10 text-left bg-white border border-gray-300 rounded-md shadow-sm cursor-default sm:text-sm"
                                            onChange={(value: any) =>
                                                handleFormDetailChange("requirements", value.target.value)
                                            }
                                        />
                                    </div>
                                    <div className="flex flex-col gap-2 w-2/5">
                                        <label
                                            className="text-sm font-bold truncate"
                                            htmlFor="onHireDate"
                                        >
                                            Survey Required Date
                                        </label>
                                        <DateBoxField
                                            date={formValues.onHireDate}
                                            onChange={(date: Date) =>
                                                handleFormDetailChange("onHireDate", date)
                                            }
                                            label=""
                                            className="flex items-center w-full"
                                        />
                                        <label
                                            className="text-sm font-bold truncate mt-4"
                                            htmlFor="surveyor"
                                        >
                                            Surveyor
                                        </label>
                                        <div className="flex flex-row items-center">
                                            <CheckboxRound
                                                checked={formValues?.surveyorOption === 2}
                                                id="isUnknown"
                                                name="isUnknown"
                                                onChange={() => {
                                                    handleFormDetailChange("surveyorOption", 2)
                                                }}
                                                small={true}
                                                darkColour={true}
                                            />
                                            <label className="text-sm ml-2">To Be Confirmed</label>
                                        </div>
                                        <div className="flex flex-row items-center mt-3">
                                            <div className="flex flex-col">
                                                <CheckboxRound
                                                    checked={formValues?.surveyorOption === 0}
                                                    id="isInternal"
                                                    name="isInternal"
                                                    onChange={() => {
                                                        handleFormDetailChange("surveyorOption", 0)
                                                    }}
                                                    small={true}
                                                    darkColour={true}
                                                />
                                            </div>
                                            <div className="flex flex-col">
                                                <label className="text-sm ml-2">Internal</label>
                                            </div>
                                            <div className="flex flex-col flex-grow">
                                                <Select
                                                    isDisabled={formValues.surveyorOption !== 0}
                                                    id="surveyor"
                                                    value={formValues.surveyor}
                                                    options={surveyors}
                                                    placeholder={"Surveyor"}
                                                    className="text-sm ml-4"
                                                    onChange={value => {
                                                        handleFormDetailChange("surveyor", value)
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className="flex flex-row items-center mt-3">
                                            <CheckboxRound
                                                checked={formValues?.surveyorOption === 1}
                                                id="isPartner"
                                                name="isPartner"
                                                onChange={() => {
                                                    handleFormDetailChange("surveyorOption", 1)
                                                }}
                                                small={true}
                                                darkColour={true}
                                            />
                                            <label className="text-sm ml-2">Partner</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <AlertMessage
                        mode={formStatus.status}
                        messages={formStatus?.messages}
                        show={formStatus?.messages?.length > 0}
                    />
                    <section className="flex flex-wrap justify-between my-6">
                        <div className="flex items-center mb-5">
                            <Button
                                className={isSaving ? "cursor-not-allowed" : ""}
                                color="grey"
                                onClick={handleOnCancel}
                                disabled={isSaving}
                            >
                                Cancel
                            </Button>
                        </div>
                        <div className="flex items-center mb-5">
                            <Button
                                className={isSaving ? "cursor-not-allowed" : ""}
                                onClick={handleOnCreate}
                                disabled={isSaving}
                            >
                                Create
                            </Button>
                        </div>
                    </section>
                </div>
            </div>
            <AddAmendAddressModal
                handleDetailChange={handleFormDetailChange}
                isOpen={isOpenAddress}
                setIsOpen={setIsOpenAddress}
                isOpenConfirm={isOpenConfirmAddress}
                setIsOpenConfirm={setIsOpenConfirmAddress}
                ctaLabel={confirmAddressCTA}
                setNewAddressValues={setNewAddressValues}
                newAddressValues={newAddressValues}
                setCanAmend={setCanAmendAddress}
                addressKeyName={"siteAddress"}
                onCancelled={() => {
                    if (previousSiteAddress) {
                        handleFormDetailChange("siteAddress", previousSiteAddress)
                    }
                }}
            />
            <AddAmendContactModal
                handleDetailChange={handleFormDetailChange}
                isOpen={isOpenContact}
                setIsOpen={setIsOpenContact}
                ctaLabel={confirmContactCTA}
                isOpenConfirm={isOpenConfirmContact}
                setIsOpenConfirm={setIsOpenConfirmContact}
                setNewContactValues={setNewContactValues}
                newContactValues={newContactValues}
                setCanAmend={setCanAmendContact}
                contactKeyName={"siteContact"}
                onCancelled={() => {
                    if (previousSiteContact) {
                        handleFormDetailChange("siteContact", previousSiteContact)
                    }
                }}
            />
        </>
    )
}