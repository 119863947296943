import React from "react"

const StatisticsIcon = () => {
    return (
        <svg
        className="h-[40px] w-[40px]"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 48 48"
        fill="none"
        >
            <g id="White_outline_-_Transparent_BG_-_Discs" data-name="White outline - Transparent BG - Discs">
                <g id="Background">
                    <path 
                        d="M24,1A23,23,0,1,1,1,24,23,23,0,0,1,24,1m0-1A24,24,0,1,0,48,24,24,24,0,0,0,24,0Z" 
                        fill="#3A3A3A"
                    />
                </g>
            </g>
            <g id="Fills_-_to_output_-_White" data-name="Fills - to output - White">
                <path 
                    d="M22,20H12.52a.5.5,0,0,1-.5-.5V11.27a.5.5,0,0,1,.5-.5H22a.5.5,0,0,1,.5.5v8.27A.5.5,0,0,1,22,20Zm-9-1h8.46V11.77H13Z" 
                    fill="#3A3A3A"
                />
                <path 
                    d="M22,37.24H12.52a.5.5,0,0,1-.5-.5V23a.5.5,0,0,1,.5-.5H22a.5.5,0,0,1,.5.5V36.74A.5.5,0,0,1,22,37.24Zm-9-1h8.46V23.53H13Z" 
                    fill="#3A3A3A"
                />
                <path 
                    d="M35.48,37.24H26a.5.5,0,0,1-.5-.5V28.46A.5.5,0,0,1,26,28h9.46a.5.5,0,0,1,.5.5v8.28A.5.5,0,0,1,35.48,37.24Zm-9-1H35V29H26.52Z" 
                    fill="#3A3A3A"
                />
                <path 
                    d="M35.48,25.47H26a.5.5,0,0,1-.5-.5V11.27a.5.5,0,0,1,.5-.5h9.46a.5.5,0,0,1,.5.5V25A.5.5,0,0,1,35.48,25.47Zm-9-1H35V11.77H26.52Z" 
                    fill="#3A3A3A"
                />
            </g>
        </svg>
    )
}

export default StatisticsIcon