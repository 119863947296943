import { useMsal } from "@azure/msal-react"
import React, { Fragment, useEffect, useState } from "react"
import { Dialog, Transition } from "@headlessui/react"
import AlertMessage from "../alert-message"
import { getEquipmentList, internalSubmitCustomerAdditionalTransportCharges } from "../../middleware/middleware-layer"
import CurrencyInput from "react-currency-input-field"
import { AdditionalTransportChargesItemsModal } from "../additional-transport-charges-items-modal"

export interface IAdditionalTransportChargesModalProps {
    isOpen: boolean
    setIsOpen: (bool: boolean) => void
    customer: Record<string, any> | null
    defaultAdditionalTransportCharges: Record<string, any> | null
    customerAdditionalTransportCharges: Record<string, any> | null
}

export const AdditionalTransportChargesModal = (props: IAdditionalTransportChargesModalProps) => {
    const [confirmButtonEnabled, setConfirmButtonEnabled] = useState<boolean>(false)
    const { accounts, instance } = useMsal()

    const initialAdditionalTransportChargesFormValues = {
        tier1: "",
        tier2: "",
        tier3: "",
        tier4: "",
        tier5: "",
    }

    const [additionalTransportChargesFormValues, setAdditionalTransportChargesFormValues] = 
        useState<Record<string, any>>(initialAdditionalTransportChargesFormValues)

    const initialAdditionalTransportChargesResult = {
        hasResult: false,
        status: "success",
        messages: [],
    }

    const [additionalTransportChargesResult, setAdditionalTransportChargesResult] = 
        useState<Record<string, any>>(initialAdditionalTransportChargesResult)

    const [equipmentList, setEquipmentList] = useState<Record<string, any>[]>([])
    const [categoryItems, setCategoryItems] = useState<Record<string, any>>([])
    const [categoryItemsTier, setCategoryItemsTier] = useState<number>(0)
    const [isCategoryItemsModalOpen, setIsCategoryItemsModalOpen] = useState<boolean>(false)

    const handleFormValueChange = (
        key: string,
        value: string | boolean | Record<string, any>
        ) => {
            setAdditionalTransportChargesFormValues({
                ...additionalTransportChargesFormValues,
                [key]: value,
            })

            setConfirmButtonEnabled(true)
    }

    function closeModal() {
        props.setIsOpen(false)
        setConfirmButtonEnabled(true)
        setAdditionalTransportChargesResult(initialAdditionalTransportChargesResult)
        setAdditionalTransportChargesFormValues(initialAdditionalTransportChargesFormValues)
    }

    const onConfirm = async () => {
        setConfirmButtonEnabled(false)

        internalSubmitCustomerAdditionalTransportCharges(
            accounts,
            instance,
            +props.customer?.customerId,
            parseFloat(additionalTransportChargesFormValues.tier1) || null,
            parseFloat(additionalTransportChargesFormValues.tier2) || null,
            parseFloat(additionalTransportChargesFormValues.tier3) || null,
            parseFloat(additionalTransportChargesFormValues.tier4) || null,
            parseFloat(additionalTransportChargesFormValues.tier5) || null,
        )
        .then((result: Record<string, any>) => {
            const {
                success,
                errorMessage,
            } = result?.data?.internalSubmitCustomerAdditionalTransportCharges

            if (!success) {
                setAdditionalTransportChargesResult({
                    hasResult: true,
                    status: "danger",
                    messages: [
                        "Unable to update the category transport charges.",
                        errorMessage
                    ]
                })
            } else {
                closeModal()
            }
        })
        .catch(err => {
            console.log(err)
            setAdditionalTransportChargesResult({
                hasResult: true,
                status: "danger",
                messages: [
                    "There was a problem.",
                    err
                ]
            })
        })
    }

    const viewCategoryChargeHireItems = async (tier: number) => {
        const setCategoryItemsByTier = (tier: number, items: Record<string, any>) => {
            setCategoryItems(items.filter((item: any) => item.tier === `tier${tier}`))
        }

        if (equipmentList.length === 0) {
            await getEquipmentList(accounts, instance, props.customer?.customerId)
                .then((results: any) => {
                    const equipment =
                        results?.data?.internalGetEquipmentListByCustomerId?.equipments || []
                    const customerEquipmentList = equipment.map((item: Record<string, any>) => {
                        return {
                            code: item.pricingEquipmentClass === "XH" ? item.pcode : item.pricingEquipmentClass,
                            description: item.equipmentDescription,
                            tier: item.additionalTransportChargeTier,
                        }
                    })
                    
                    setEquipmentList(customerEquipmentList)
                    setCategoryItemsByTier(tier, customerEquipmentList)
                })
                .catch(err => {
                    console.log(err)
                })
        } else {
            setCategoryItemsByTier(tier, equipmentList)
        }

        setCategoryItemsTier(tier)
        setIsCategoryItemsModalOpen(true)
    }

    useEffect(() => {
        if (props.customerAdditionalTransportCharges) {
            setAdditionalTransportChargesFormValues(props.customerAdditionalTransportCharges)
        }
    }, [props.customerAdditionalTransportCharges])

    return (
        <>
            <Transition appear show={props.isOpen} as={Fragment}>
                <Dialog
                    as="div"
                    className="relative z-10 font-normal"
                    onClose={closeModal}
                >
                    <Transition.Child
                        as={Fragment}
                        enter="ease-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in duration-200"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-black bg-opacity-25" />
                    </Transition.Child>
                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="flex items-center justify-center min-h-full text-center">
                            <Transition.Child
                                as={Fragment}
                                enter="ease-out duration-300"
                                enterFrom="opacity-0 scale-95"
                                enterTo="opacity-100 scale-100"
                                leave="ease-in duration-200"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <Dialog.Panel className="max-w-xl px-8 py-8 overflow-hidden align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                                    <Dialog.Title
                                        as="h3"
                                        className="text-xl font-bold leading-6 text-gray-900"
                                    >
                                        CATEGORY TRANSPORT CHARGES
                                    </Dialog.Title>
                                    <div className="m-4">
                                        <p>If you want to apply the default charge for a category, leave the value empty or remove the current value.</p>
                                    </div>
                                    <form>
                                        <div className="flex flex-col w-4/5 items-center mx-auto my-8">
                                            {[1, 2, 3, 4, 5].map((tier, index) =>{
                                                return (
                                                    
                                                        <div
                                                            className="flex flex-row mb-2 w-full"
                                                            key={`additional-transport-tier-${index}`}
                                                        >
                                                            <div className="flex flex-col w-2/5 items-end justify-center mr-2">
                                                                <p className="text-sm font-bold">
                                                                    {`Category ${tier}:`}
                                                                </p>
                                                            </div>
                                                            <div className="flex flex-col w-3/5">
                                                                <CurrencyInput
                                                                    id={`tier-${tier}`}
                                                                    name={`tier-${tier}`}
                                                                    placeholder={`Default charge (£${(props.defaultAdditionalTransportCharges?.[`tier${tier}`] || 0).toFixed(2)})`}
                                                                    prefix="£"
                                                                    value={additionalTransportChargesFormValues[`tier${tier}`]}
                                                                    decimalsLimit={2}
                                                                    decimalScale={2}
                                                                    onValueChange={(value = "") => {
                                                                        handleFormValueChange(
                                                                            `tier${tier}`,
                                                                            value
                                                                        )
                                                                    }}
                                                                    allowNegativeValue={false}
                                                                    className="relative w-full py-2 pl-3 pr-2 text-left bg-white border border-gray-300 rounded-md shadow-sm cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                                                    autoComplete="off"
                                                                />
                                                            </div>
                                                            <div className="flex flex-col w-2/5 items-start justify-center ml-2">
                                                            <a
                                                                className="text-xs underline cursor-pointer"
                                                                onClick={(e) => {
                                                                    e.preventDefault()
                                                                    viewCategoryChargeHireItems(tier)
                                                                }}
                                                            >
                                                                View hire items
                                                            </a>
                                                            </div>
                                                        </div>
                                                )
                                            })}
                                        </div>

                                        {additionalTransportChargesResult.hasResult && (
                                            <div className="m-4 text-left">
                                                <AlertMessage
                                                    mode={additionalTransportChargesResult.status}
                                                    messages={additionalTransportChargesResult.messages}
                                                    show={true}
                                                />
                                            </div>
                                        )}
                    
                                        <div className="flex flex-row justify-around mx-4 mt-8">
                                            <button
                                                onClick={e => {
                                                    e.preventDefault()
                                                    closeModal()
                                                }}
                                                className="px-12 py-2 bg-white border border-gray-500 rounded-full hover:bg-horizonhover hover:text-white hover:border-transparent sm:text-sm"
                                            >
                                                {additionalTransportChargesResult.hasResult ? "CLOSE" : "CANCEL"}
                                            </button>
                                            {!additionalTransportChargesResult.hasResult && (
                                                <button
                                                    disabled={!confirmButtonEnabled}
                                                    onClick={e => {
                                                        e.preventDefault()
                                                        onConfirm()
                                                    }}
                                                    className={confirmButtonEnabled 
                                                        ? "px-12 py-2 text-white rounded-full bg-horizonred focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 hover:bg-horizonhover hover:text-white hover:border-transparent sm:text-sm cursor-pointer"
                                                        : "px-12 py-2 bg-white border border-gray-200 text-gray-200 rounded-full sm:text-sm"
                                                    }
                                                >
                                                    CONFIRM
                                                </button>
                                            )}
                                        </div>
                                    </form>
                                </Dialog.Panel>
                            </Transition.Child>
                        </div>
                    </div>
                    <AdditionalTransportChargesItemsModal
                        isOpen={isCategoryItemsModalOpen}
                        items={categoryItems}
                        setIsOpen={setIsCategoryItemsModalOpen}
                        tier={categoryItemsTier}
                    />
                </Dialog>
            </Transition>
        </>
    )
}