import React, { Fragment } from "react"
import { Dialog, Transition } from "@headlessui/react"
import { useResourceStringsNewContracts } from "../pages/cx-dashboard/customer/customer-detail/new-contracts/use-resource-strings"
import { AddressForm } from "../address-form"

export interface IAddAmendAddressModalProps {
  handleDetailChange: (key: string, value: string | Record<string, any>) => void
  isOpen: boolean
  setIsOpen: (bool: boolean) => void
  isOpenConfirm?: boolean
  setIsOpenConfirm?: (bool: boolean) => void
  ctaLabel: Record<string, any>
  setNewAddressValues: any
  newAddressValues: Record<string, any>
  setCanAmend: any
  addressKeyName: string
  onCancelled: () => void
}

export const AddAmendAddressModal = ({
  handleDetailChange,
  isOpen,
  setIsOpen,
  isOpenConfirm = false,
  setIsOpenConfirm,
  ctaLabel,
  setNewAddressValues,
  newAddressValues,
  setCanAmend,
  addressKeyName,
  onCancelled,
}: IAddAmendAddressModalProps) => {
  const { amendExistingContactWarning } = useResourceStringsNewContracts()

  function openModal() {
    setIsOpen(true)
  }

  function closeModal() {
    setIsOpen(false)
  }

  function closeModalConfirm() {
    setIsOpenConfirm && setIsOpenConfirm(false)
  }

  const onConfirm = (address: any) => {
    handleDetailChange(addressKeyName, address)
    setCanAmend(true)
    closeModal()
  }

  const onCancel = () => {
    onCancelled()
    closeModal()
  }

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10 font-normal"
          onClose={closeModal}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md px-2 py-8 overflow-hidden align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                  <Dialog.Title
                    as="h3"
                    className="text-xl font-bold leading-6 text-gray-900"
                  >
                    {ctaLabel.title}
                  </Dialog.Title>
                  <AddressForm
                    ctaLabel={ctaLabel}
                    newAddressValues={newAddressValues}
                    onCancelled={onCancel}
                    onConfirmed={(address) => onConfirm(address)}
                    setNewAddressValues={setNewAddressValues}
                  />
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>

      <Transition appear show={isOpenConfirm} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10 font-normal"
          onClose={closeModalConfirm}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-full text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md px-2 py-8 overflow-hidden align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                  <Dialog.Title
                    as="h3"
                    className="text-xl font-bold leading-6 text-gray-900"
                  >
                    CONFIRM AMENDMENT OF EXISTING ADDRESS
                  </Dialog.Title>
                  <form>
                    <div className="flex flex-col gap-4 m-4 items-center">
                      <div className="flex flex-row gap-2">
                        <p className="text-sm font-bold">
                          {amendExistingContactWarning}
                        </p>
                      </div>
                    </div>
                    <div className="flex flex-row gap-2 mx-4">
                      <div className="flex flex-1">
                        <button
                          onClick={e => {
                            e.preventDefault()
                            closeModalConfirm()
                          }}
                          className="w-full px-4 py-2 bg-white border border-gray-500 rounded-full hover:bg-horizonhover hover:text-white hover:border-transparent sm:text-sm"
                        >
                          CANCEL
                        </button>
                      </div>
                      <div className="flex flex-1">
                        <button
                          onClick={e => {
                            e.preventDefault()
                            closeModalConfirm()
                            setTimeout(() => {
                              openModal()
                            }, 300)
                          }}
                          className="w-full px-4 py-2 text-white rounded-full bg-horizonred focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 hover:bg-horizonhover hover:text-white hover:border-transparent sm:text-sm"
                        >
                          CONFIRM
                        </button>
                      </div>
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}
