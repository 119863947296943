import React, { useEffect, useState } from "react"
import { useMsal } from "@azure/msal-react"
import Moment from "react-moment"
import { Link } from "gatsby"
import { useCustomerContext } from "../../store"
import { useResourceStringsNewContracts } from "./use-resource-strings"
import { getCustomerWithSavedContractsByCustomerId } from "../../../../../../middleware/middleware-layer"
import { CustomerContracts } from "./customer-contract-list"
import { NewContractButton } from "../../../../../new-contract-button"
import { AlertBanner } from "../../../../../alert-banner"
import { HireInsuranceStatus } from "../../../../../hire-insurance-status"
import { useQueryParams } from "../../../../../../utils/browser-helper"

export default function NewContracts(props: any) {
  const { customer } = useCustomerContext()
  const { instance, accounts } = useMsal()
  const [customerContracts, setCustomerContracts] = useState([])
  const [loading, setLoading] = useState(true)
  const [noResponse, setNoResponse] = useState(false)

  const {
    contractNo,
    status,
    endDate,
    startDate,
    productOrderNo,
    site,
    contact,
    staffEntered,
  } = useResourceStringsNewContracts()

  useEffect(() => {
    getContracts()
  }, [useQueryParams().size])

  const getContracts = () => {
    setLoading(true)
    getCustomerWithSavedContractsByCustomerId(
      accounts,
      instance,
      props.customerId
    )
    .then((results: any) => {
      const filter = useQueryParams().get("status")
      let contracts: any = {}
      if (filter?.length || 0 > 0) {
        contracts = results?.data?.internalGetSavedContractsByCustomerId?.contracts.filter((contract: any) => {
          return contract.statusDescription === "Quote Raised" || contract.statusDescription.toLowerCase() === filter?.toLowerCase()
        })
      } else {
        contracts = results?.data?.internalGetSavedContractsByCustomerId?.contracts
      }
      
      const parseContractData =
        contracts.map(
          (contract: any) => {
            return {
              contractNo: contract.contractNo,
              contractId: contract.contractId,
              statusDescription:
                contract.statusDescription === "Quote Raised"
                  ? "Unconfirmed"
                  : contract.statusDescription,
              hireStartDate: contract.hireStartDate,
              plannedEndDate: contract.plannedEndDate,
              orderNo: contract.orderNo,
              siteName: contract.siteName,
              contact: contract.contact,
              staffEntered: contract.staffEntered,
            }
          }
        )

      setCustomerContracts(parseContractData)
      setLoading(false)
    })
    .catch(() => setNoResponse(true))
  }

  const columns = [
    {
      id: "contractNo",
      name: contractNo,
      valueKeys: ["contractNo", "contractId"],
      className: "",
      renderCell: (values?: Record<string, any | string>) => {
        const contractNo = values?.[0] || ""
        const contractId = values?.[1] || ""
        return (
          <Link
            to={`/cx-dashboard/customer/${props.customerId}/contracts/saved/${contractId}`}
          >
            {contractNo}
          </Link>
        )
      },
    },
    {
      id: "statusDescription",
      name: status,
      valueKeys: ["statusDescription"],
      className: "",
    },
    {
      id: "hireStartDate",
      name: startDate,
      valueKeys: ["hireStartDate"],
      className: "",
      renderCell: (values?: Record<string, any | string>) => {
        if (values && values[0] == null) {
          return
        }
        const valueString = (values && values.join(", ")) || ""
        return <Moment format="DD-MM-YYYY">{valueString}</Moment>
      },
    },
    {
      id: "plannedEndDate",
      name: endDate,
      valueKeys: ["plannedEndDate"],
      className: "",
      renderCell: (values?: Record<string, any | string>) => {
        if (values && values[0] == null) {
          return
        }
        const valueString = (values && values.join(", ")) || ""
        return <Moment format="DD-MM-YYYY">{valueString}</Moment>
      },
    },
    {
      id: "orderNo",
      name: productOrderNo,
      valueKeys: ["orderNo"],
      className: "",
    },
    {
      id: "siteName",
      name: site,
      valueKeys: ["siteName"],
      className: "",
    },
    {
      id: "contact",
      name: contact,
      valueKeys: ["contact"],
      className: "",
    },
    {
      id: "staffEntered",
      name: staffEntered,
      valueKeys: ["staffEntered"],
      className: "",
    },
  ]

  return (
    <div>
      <div className="flex flex-row justify-end">
        <h2 className="flex-1 inline-flex mt-8 mb-6 mr-6 text-3xl font-bold uppercase">
          {customer?.customerName && customer?.customerName?.toUpperCase()}
        </h2>
        <NewContractButton 
          accounts={accounts}
          className="mt-8"
          customer={customer}
          instance={instance}
        />
      </div>
      <HireInsuranceStatus
          hasInsurance={customer?.hasInsurance}
          insuranceRenewalDate={customer?.insuranceRenewalDate}
          classNames="mb-6"
        />
      {customer?.alertText && 
        <div className="mb-6">
          <AlertBanner text={customer?.alertText} />
        </div>
      }
      {!noResponse ? (
        customer?.customerName && (
          <>
            <CustomerContracts
              customerContracts={customerContracts}
              loading={loading}
              columns={columns}
            />
          </>
        )
      ) : (
        <div>
          <h2 className="w-full py-16 text-2xl font-bold uppercase">
            Issue fetching data
          </h2>
        </div>
      )}
    </div>
  )
}
