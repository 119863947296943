import { classNames } from "../../../utils/classNames"
import React from "react"

export interface FilteringPillsProps {
  title?: string
  buttons?: Record<string, any>[]
  setSelectedButton: any
  selectedButton: any
  allFilterButton: Record<string, any>
}

export const FilteringPills = ({
  title,
  buttons,
  setSelectedButton,
  selectedButton,
  allFilterButton,
}: FilteringPillsProps) => {
  const buttonClassName =
    "normal-case px-5 py-2.5 border text-xs 2xl:text-sm 4xl:text-md font-medium rounded-full hover:bg-filteringPillsHover hover:text-white transition-colors focus:outline-none focus:ring-2 focus:ring-offset-2"

  return (
    <div className="flex items-center justify-between px-4 py-3 border-b sm:px-6 lg:px-8">
      {title && <p className="font-bold uppercase">{title}</p>}
      <div className="flex space-x-2">
        <button
          key={allFilterButton.name}
          onClick={() => {
            setSelectedButton("all")
            allFilterButton.onClick && allFilterButton.onClick(null)
          }}
          className={classNames(
            "all" === selectedButton
              ? "bg-filteringPillsHover text-white"
              : "text-black ",
            buttonClassName
          )}
        >
          {allFilterButton.title}
        </button>
        {buttons &&
          buttons.map(({ name, title, onClick }) => {
            return (
              <button
                key={name}
                onClick={() => {
                  setSelectedButton(name)
                  onClick && onClick({ label: name, value: name })
                }}
                className={classNames(
                  name === selectedButton
                    ? "bg-filteringPillsHover text-white"
                    : "text-black",
                  buttonClassName
                )}
              >
                {title}
              </button>
            )
          })}
      </div>
    </div>
  )
}
