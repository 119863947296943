import { useAllKontentResourceStringData } from "../../../../../../../graphql-static/use-resource-strings"
import { getResourceStringByid } from "../../../../../../../utils/resource-string-helper"
import { AuthenticatedResourcesStrings } from "../../../../../../../types/enums/authenticated-resource-strings"

export const useResourceStringsOutOnHire = () => {
  const resourceStrings = useAllKontentResourceStringData()

  const contractNo = getResourceStringByid(
    resourceStrings,
    AuthenticatedResourcesStrings.INTERNAL_AUTHENTICATED_CXDASHBOARD_OUTONHIRE_GRID_COLUMNHEADER_CONTRACTNO
  )
  const status = getResourceStringByid(
    resourceStrings,
    AuthenticatedResourcesStrings.INTERNAL_AUTHENTICATED_CXDASHBOARD_OUTONHIRE_GRID_COLUMNHEADER_STATUS
  )
  const startDate = getResourceStringByid(
    resourceStrings,
    AuthenticatedResourcesStrings.INTERNAL_AUTHENTICATED_CXDASHBOARD_OUTONHIRE_GRID_COLUMNHEADER_STARTDATE
  )
  const endDate = getResourceStringByid(
    resourceStrings,
    AuthenticatedResourcesStrings.INTERNAL_AUTHENTICATED_CXDASHBOARD_OUTONHIRE_GRID_COLUMNHEADER_ENDDATE
  )
  const productOrderNo = getResourceStringByid(
    resourceStrings,
    AuthenticatedResourcesStrings.INTERNAL_AUTHENTICATED_CXDASHBOARD_OUTONHIRE_GRID_COLUMNHEADER_PRODUCTORDERNO
  )
  const site = getResourceStringByid(
    resourceStrings,
    AuthenticatedResourcesStrings.INTERNAL_AUTHENTICATED_CXDASHBOARD_OUTONHIRE_GRID_COLUMNHEADER_SITE
  )
  const contact = getResourceStringByid(
    resourceStrings,
    AuthenticatedResourcesStrings.INTERNAL_AUTHENTICATED_CXDASHBOARD_OUTONHIRE_GRID_COLUMNHEADER_CONTACT
  )

  const offHireOrderButton = getResourceStringByid(
    resourceStrings,
    AuthenticatedResourcesStrings.INTERNAL_AUTHENTICATED_CXDASHBOARD_OUTONHIRE_GRID_BUTTON_OFFHIREORDER
  )

  const partialOffHireButton = getResourceStringByid(
    resourceStrings,
    AuthenticatedResourcesStrings.INTERNAL_AUTHENTICATED_CXDASHBOARD_OUTONHIRE_GRID_BUTTON_PARTIALOFFHIRE
  )

  const noContracts = getResourceStringByid(
    resourceStrings,
    AuthenticatedResourcesStrings.INTERNAL_AUTHENTICATED_CXDASHBOARD_OUTONHIRE_NOCONTRACTS
  )

  return {
    contractNo,
    status,
    endDate,
    startDate,
    productOrderNo,
    site,
    contact,
    offHireOrderButton,
    partialOffHireButton,
    noContracts,
  }
}
