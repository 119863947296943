import React from "react"
import DatePicker from "react-datepicker"

export interface IDateBox {
  label: string
  className: string
  onChange: any
  date: Date | null
}

export const DateBoxField = ({ className, onChange, date }: IDateBox) => {
  const formattedDate = date ? new Date(date) : null

  return (
    <div className={className}>
      <div className="flex items-center w-full">
        <DatePicker
          selected={formattedDate}
          onChange={onChange}
          dateFormat="dd/MM/yyyy"
          showDisabledMonthNavigation
          placeholderText="DD/MM/YYYY"
          className="relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-2 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          isClearable
        />
      </div>
    </div>
  )
}

export default DateBoxField
