import React from "react"
import AsyncSelect from "react-select/async"
import Select from "react-select"
import DatePicker from "react-datepicker"
import InputDateRangePicker from "../../input-datepicker-range"

export interface FilteringOptionsProps {
  title?: String
  fields: Record<string, any>[]
  filterOptionsInputValues: Record<string, any>
  submitButtonTitle?: string
  clearFilters?: () => void
  resetErrors?: () => void
  handleSubmit?: () => void
  clearButtonTitle?: string
  disableSubmit?: boolean
}

export const FilteringOptions = ({
  title,
  submitButtonTitle,
  fields,
  filterOptionsInputValues,
  clearFilters,
  resetErrors,
  handleSubmit,
  clearButtonTitle,
  disableSubmit,
}: FilteringOptionsProps) => {
  const submitButtonClassName = `${
    disableSubmit ? "cursor-not-allowed" : ""
  } self-start px-4 py-2 mr-3 border border-transparent text-sm font-medium rounded-full shadow-sm text-white bg-horizonred focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500`

  return (
    <>
      {title && (
        <h3 className="text-xl uppercase mb-4 font-semibold">{title}</h3>
      )}
      <form className="flex flex-col w-full">
        <div className="grid w-full grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-4">
          {fields.map(
            ({
              id,
              type,
              label,
              placeholder,
              loadOptions,
              options,
              handleOnChange,
              error,
            }) => {
              switch (type) {
                case "asyncSelect":
                  return (
                    <div key={id}>
                      <label htmlFor={id}>
                        {label}{" "}
                        {error && (
                          <span className="text-contractStatusAmber">
                            &nbsp;{error}
                          </span>
                        )}
                      </label>

                      <AsyncSelect
                        id={id}
                        value={filterOptionsInputValues[id]}
                        placeholder={placeholder}
                        loadOptions={loadOptions}
                        onChange={handleOnChange}
                        isClearable
                        onBlur={resetErrors}
                      />
                    </div>
                  )
                case "select":
                  return (
                    <div key={id}>
                      <label htmlFor={id}>
                        {label}{" "}
                        {error && (
                          <span className="text-contractStatusAmber">
                            &nbsp;{error}
                          </span>
                        )}
                      </label>

                      <Select
                        id={id}
                        value={filterOptionsInputValues[id]}
                        placeholder={placeholder}
                        options={options}
                        onChange={handleOnChange}
                        isClearable
                        onBlur={resetErrors}
                      />
                    </div>
                  )
                case "datePicker":
                  return (
                    <div key={id}>
                      <label htmlFor={id}>
                        {label}{" "}
                        {error && (
                          <span className="text-contractStatusAmber">
                            &nbsp;{error}
                          </span>
                        )}
                      </label>

                      <div className="relative">
                        <DatePicker
                          selected={filterOptionsInputValues[id]}
                          onChange={handleOnChange}
                          dateFormat="dd-MM-yyyy"
                          showDisabledMonthNavigation
                          placeholderText={placeholder}
                          className="relative w-full py-2 pl-3 pr-10 text-left bg-white border border-gray-300 rounded-md shadow-sm cursor-default sm:text-sm"
                        />
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width={22.065}
                          height={24.406}
                          className="absolute top-[7px] right-[15px]"
                        >
                          <defs>
                            <style>
                              {
                                ".a{fill:none;stroke:#4c4a4a;stroke-linecap:round;stroke-linejoin:round}"
                              }
                            </style>
                          </defs>
                          <path
                            className="a"
                            d="M2.841 2.953h16.383a2.334 2.334 0 0 1 2.341 2.328v16.3a2.334 2.334 0 0 1-2.341 2.328H2.841A2.334 2.334 0 0 1 .5 21.578V5.281a2.334 2.334 0 0 1 2.341-2.328ZM15.714.5v4.906M6.351.5v4.906M.5 9.862h21.065"
                          />
                        </svg>
                      </div>
                    </div>
                  )
                case "dateRange":
                  return (
                    <div key={id} className="grid-cols-2">
                      <label htmlFor={id}>
                        {label}{" "}
                        {error && (
                          <span className="text-contractStatusAmber">
                            &nbsp;{error}
                          </span>
                        )}
                      </label>
                      <InputDateRangePicker
                        key={id}
                        selectedArr={filterOptionsInputValues[id]}
                        callback={(startDate: any, endDate: any) => {
                          handleOnChange([startDate, endDate])
                        }}
                      />
                    </div>
                  )
              }
            }
          )}
        </div>
        <div className="flex items-end mt-4">
          {handleSubmit && (
            <button
              type="button"
              className={submitButtonClassName}
              onClick={e => {
                e.preventDefault()
                handleSubmit()
              }}
              disabled={disableSubmit}
            >
              {submitButtonTitle}
            </button>
          )}

          <button
            onClick={clearFilters}
            className="text-xs underline"
            type="button"
          >
            {clearButtonTitle}
          </button>
        </div>
      </form>
    </>
  )
}
