import React from "react"

interface SiteSurveyStatusProps {
  isSiteSurvey: boolean | false
  siteSurveyAssigned: boolean | false
  handleAssignSurveyor: () => void
  handleCompleteSurvey: () => void
  classNames?: string
}

export const SiteSurveyStatus = (props: SiteSurveyStatusProps) => {
    if (!!(props.isSiteSurvey)) {
        return (
            <div className={`flex overflow-hidden bg-white border rounded-lg border-filteringPillsHover`}>
                <div className={`flex items-center justify-center p-5 text-white bg-filteringPillsHover`}>
                    <svg
                    className="w-auto h-4"
                    xmlns="http://www.w3.org/2000/svg"
                    width="22.83"
                    height="27.787"
                    viewBox="0 0 22.83 27.787"
                    >
                    {" "}
                    <g
                        id="Icon_feather-flag"
                        data-name="Icon feather-flag"
                        transform="translate(1.5 1.5)"
                    >
                        {" "}
                        <path
                        id="Path_69483"
                        data-name="Path 69483"
                        d="M6,23.579S7.239,22,10.957,22s6.2,3.166,9.915,3.166,4.957-1.583,4.957-1.583v-19S24.59,6.166,20.872,6.166,14.675,3,10.957,3,6,4.583,6,4.583Z"
                        transform="translate(-6 -3)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="3"
                        />{" "}
                        <path
                        id="Path_69484"
                        data-name="Path 69484"
                        d="M6,25.562V12.5"
                        transform="translate(-6 -0.775)"
                        fill="none"
                        stroke="#fff"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="3"
                        />{" "}
                    </g>
                    </svg>
                </div>
                <div className="p-5">
                    <p>{`Site survey is pending${props.siteSurveyAssigned ? "" : ", no surveyor has been assigned"}`}</p>
                    <p>
                        {!props.siteSurveyAssigned && (
                            <>
                                <button 
                                    className="pb-1 text-right underline bg-white sm:text-xs"
                                    onClick={(e) => {
                                        e.preventDefault()
                                        props.handleAssignSurveyor()
                                    }}
                                >
                                    Assign surveyor
                                </button>
                                <label className="sm:text-xs"> or </label>
                            </>
                        )}
                        <button 
                            className="pb-1 text-right underline bg-white sm:text-xs"
                            onClick={(e) => {
                                e.preventDefault()
                                props.handleCompleteSurvey()
                            }}
                        >
                            Complete survey
                        </button>
                    </p>
                </div>
            </div>
        )
    }

    return null
}