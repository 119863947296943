import React from "react"
import { AccountInfo } from "@azure/msal-common"
import { IPublicClientApplication } from "@azure/msal-browser"
import { navigate } from "gatsby-link"

export interface INewSiteSurveyButtonProps {
    accounts: AccountInfo[]
    className?: string
    customer: Record<string, any>
    instance: IPublicClientApplication
}

export const NewSiteSurveyButton = (props: INewSiteSurveyButtonProps) => {
    return (
        <button
            className={`${props.className ? `${props.className} ` : ""}self-start px-4 py-2 mr-12 text-sm uppercase font-medium border border-transparent rounded-full shadow-sm group text-white bg-filteringPillsHover hover:bg-tertiaryHover hover:text-horizonhover`}
            onClick={(e) => {
                e.preventDefault()
                navigate(`/cx-dashboard/customer/${props.customer.customerId}/site-survey`)
            }}
        >
            NEW SITE SURVEY
        </button>
    )
}