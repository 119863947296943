import React, { Fragment } from "react"
import { Disclosure, Transition } from "@headlessui/react"
import { ChevronDownIcon } from "@heroicons/react/solid"
import { FilterIcon } from "./icons"
import { classNames } from "../../utils/classNames"
import { FilteringPills } from "./filtering-pills"
import { FilteringOptions } from "./filtering-options"

export interface DynamicFilteringMenuProps {
  topFilteringTitle?: string
  topFilteringButtons?: Record<string, any>[]
  filteringOptionsTitle?: string
  filterOptionsFields: Record<string, any>[]
  filterOptionsInputValues: Record<string, any>
  clearFilters: () => void
  buttonTitle?: string
  handleSubmit?: () => void
  resetErrors: () => void
  clearButtonTitle?: string
  defaultOpen?: boolean
  disableSubmit?: boolean
  selectedButton?: any
  setSelectedButton?: any
  allFilterButton?: any
}

export const DynamicFilteringMenu = ({
  topFilteringTitle,
  topFilteringButtons,
  filteringOptionsTitle = "Advanced Filtering Option",
  filterOptionsFields,
  filterOptionsInputValues,
  buttonTitle = "Search",
  handleSubmit,
  resetErrors,
  clearButtonTitle = "Clear",
  clearFilters,
  defaultOpen,
  disableSubmit,
  selectedButton,
  setSelectedButton,
  allFilterButton,
}: DynamicFilteringMenuProps) => {
  const disclosureButtonClassName =
    "group bg-white rounded-md inline-flex items-center ml-auto text-base font-medium hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"

  const hideTopFilteringSection = !topFilteringTitle && !topFilteringButtons

  return (
    <div className="mb-5 bg-white border rounded-md">
      {!hideTopFilteringSection && (
        <FilteringPills
          title={topFilteringTitle}
          buttons={topFilteringButtons}
          setSelectedButton={setSelectedButton}
          selectedButton={selectedButton}
          allFilterButton={allFilterButton}
        />
      )}
      <Disclosure defaultOpen={defaultOpen}>
        {({ open }) => (
          <>
            <div className="flex items-center justify-between px-4 py-2 sm:px-6 lg:px-8">
              <FilterIcon />
              <p className="font-bold">{filteringOptionsTitle}</p>
              <Disclosure.Button
                className={classNames(
                  open ? "text-gray-900" : "text-gray-500",
                  disclosureButtonClassName
                )}
              >
                <ChevronDownIcon
                  className={classNames(
                    open ? "text-gray-600" : "text-gray-400",
                    "h-9 w-9 group-hover:text-gray-500"
                  )}
                  aria-hidden="true"
                />
              </Disclosure.Button>
            </div>

            <Transition
              unmount={false}
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 -translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 -translate-y-1"
            >
              <Disclosure.Panel
                unmount={false}
                className="relative z-10 px-4 py-4 transform sm:px-6 lg:px-8"
              >
                <FilteringOptions
                  submitButtonTitle={buttonTitle}
                  filterOptionsInputValues={filterOptionsInputValues}
                  fields={filterOptionsFields}
                  handleSubmit={handleSubmit}
                  clearButtonTitle={clearButtonTitle}
                  clearFilters={clearFilters}
                  resetErrors={resetErrors}
                  disableSubmit={disableSubmit}
                />
              </Disclosure.Panel>
            </Transition>
          </>
        )}
      </Disclosure>
    </div>
  )
}
